import React ,{useEffect,useState}from "react";

import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Form, FormGroup, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import "../assets/css/style.css";


function Events() {
  const [events, setEvents] = useState([]);
  const [newEvent, setNewEvent] = useState({
    title: "",
    title_ar: "",
    date: "",
    content: "",
    content_ar: "",
  });
  const [eventImage, setEventImage] = useState(null);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [updateEvent, setUpdateEvent] = useState({
    title: "",
    title_ar: "",
    date: "",
    content: "",
    content_ar: "",
    image_path: "", 
  })
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [eventCount, setEventCount] = useState(0); 
  const [addError, setAddError] = useState("");
  const [updateError, setUpdateError] = useState("");
  const [addSuccessMessage, setAddSuccessMessage] = useState("");
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState("");
  const [AddRequired, setAddRequired] = useState("");
  const [UpdateRequired, setUpdateRequired] = useState("");
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteEventId, setDeleteEventId] = useState(null);
  const [additionalImages, setAdditionalImages] = useState([]);
  const [ImageError, setImageError] = useState("");




  
  const isValidImageExtension = (fileName) => {
    if (!fileName) {
      return false;
    }
  
    const validExtensions = ['.webp', '.gif', '.png', '.jpg', '.jpeg'];
    const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase();
  
    return validExtensions.includes(`.${fileExtension}`);
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    if (showUpdateForm) {
      setUpdateEvent(prevState => ({
        ...prevState,
        [name]: value
      }));
    } else {
      setNewEvent(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };
  
  const handleImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      // Check if the target is the main image input
      if (e.target.name === 'main_image') {
        setEventImage(e.target.files[0]); // Set the new main image
      } 
      // Check if the target is the additional images input
      else if (e.target.name === 'additional_images') {
        setAdditionalImages(Array.from(e.target.files)); // Set the additional images
      }
    }
  };
  
  
  

  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!newEvent.title || !newEvent.title_ar || !newEvent.date || !newEvent.content || !newEvent.content_ar ||  !eventImage) {
      setAddRequired("All fields are required.");
      setTimeout(() => {
        setAddRequired("");
      }, 5000);
      return;
    }
  
    // Validate image file extensions
    if (!isValidImageExtension(eventImage.name)) {
      setAddError("Invalid main image file extension. Please upload .WEBP, .GIF, .PNG, .JPG, or .JPEG");
      setTimeout(() => {
        setAddError("");
      }, 5000);
      return;
    }

    // Validate file extensions for additional images
  for (let image of additionalImages) {
    if (!isValidImageExtension(image.name)) {
      setAddError("Invalid file extension in additional images. Please upload .WEBP, .GIF, .PNG, .JPG, or .JPEG");
      setTimeout(() => {
        setAddError("");
      }, 5000);
      return; // Exit the function if any additional image has an invalid extension
    }
  }
  
    const formData = new FormData();
    formData.append("title", newEvent.title);
    formData.append("title_ar", newEvent.title_ar);
    formData.append("date", newEvent.date);
    formData.append("content", newEvent.content);
    formData.append("content_ar", newEvent.content_ar);
    formData.append("main_image", eventImage); // Add main image
  
    
  
    // Add additional images to 'image_paths', ensuring no duplication with main image
    additionalImages.forEach(image => {
      if (image !== eventImage) {
        formData.append("image_paths", image);
      }
    });
  
    try {
      const response = await axios.post("https://digitalback.we-demo.xyz/event/add", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
  
      console.log("Event added successfully:", response.data);
      fetchData();
      setAddSuccessMessage("Event added successfully");
      setTimeout(() => { setAddSuccessMessage(""); }, 5000);
  
      setNewEvent({ title: "", title_ar: "", date: "", content: "", content_ar: "" });
      setEventImage(null);
      setAdditionalImages([]);
    } catch (error) {
      console.error("Error adding event:", error);
      setAddError("Error adding event");
      setTimeout(() => { setAddError(""); }, 5000);
    }
  };
  
  
  
  
  

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://digitalback.we-demo.xyz/event/list"
      );
      setEvents(response.data.data);
      setEventCount(response.data.data.length); // Update event count
      setLoading(false); // Set loading to false after data is fetched
      setError(null); // Clear any previous errors
    } catch (error) {
      console.error(`Error getting data: ${error}`);
      setLoading(false); // Set loading to false in case of error
      setError("Error fetching data. Please try again later."); // Set the error message
    }
  };

  const handleDelete = (id) => {
    setDeleteEventId(id);
    setShowDeletePopup(true);
  };
  

  const performDelete = async () => {
    try {
      await axios.delete(`https://digitalback.we-demo.xyz/event/delete/${deleteEventId}`);
      const updatedEvents = events.filter(event => event.id !== deleteEventId);
      setEvents(updatedEvents);
      setShowDeletePopup(false);
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };
  
  
  
  function formatDateToYYYYMMDD(date) {
    const eventDate = new Date(date);
    const year = eventDate.getFullYear();
    const month = (eventDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed, so we add 1
    const day = eventDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  


  
  
  

  const handleUpdate = (event) => {
    setSelectedEventId(event.id); // Set the selected event ID
    setUpdateEvent({
      title: event.title,
      title_ar: event.title_ar,
      date: formatDateToYYYYMMDD(event.date),
      content: event.content,
      content_ar: event.content_ar,
      image_path: event.main_image, // Set the current main image path
    });
    setAdditionalImages(event.image_paths || []); // Set additional images
    setShowUpdateForm(true); // Display the update form
  };
  
  

  

  const handleContentChange = (e) => {
    const { value } = e.target;

    if (showUpdateForm) {
      setUpdateEvent((prevState) => ({
        ...prevState,
        content: value,
        
      }));
      console.log("Updated Content:", value);
    } else {
      setNewEvent((prevState) => ({
        ...prevState,
        content: value,
      }));
    }
  };


  const handleContent_arChange = (e) => {
    const { value } = e.target;

    if (showUpdateForm) {
      setUpdateEvent((prevState) => ({
        ...prevState,
        content_ar: value,
        
      }));
      console.log("Updated Content:", value);
    } else {
      setNewEvent((prevState) => ({
        ...prevState,
        content_ar: value,
      }));
    }
  };

  // Function to handle the main image change
const handleMainImageChange = (e) => {
  if (e.target.files[0]) {
      setEventImage(e.target.files[0]);
  }
};

// Function to handle the additional images change
const handleAdditionalImagesChange = (e) => {
  setAdditionalImages(Array.from(e.target.files));
};
  

  const handleEventUpdate = async (e) => {
    e.preventDefault();
  
    // Validation checks
    if (!updateEvent.title || !updateEvent.title_ar || !updateEvent.date || !updateEvent.content || !updateEvent.content_ar) {
      setUpdateRequired("Title, Date, and Content are required.");
      setTimeout(() => {
        setUpdateRequired("");
      }, 5000);
      return;
    }

    // If a new main image is provided, validate its extension
  if (eventImage && !isValidImageExtension(eventImage.name)) {
    setUpdateError("Invalid main image file extension. Please upload .WEBP, .GIF, .PNG, .JPG, or .JPEG");
    setTimeout(() => {
      setUpdateError("");
    }, 5000);
    return;
  }


  
    // Initialize FormData for the PUT request
    const formData = new FormData();
    formData.append("title", updateEvent.title);
    formData.append("title_ar", updateEvent.title_ar);
    formData.append("date", updateEvent.date);
    formData.append("content", updateEvent.content);
    formData.append("content_ar", updateEvent.content_ar);

    // Append the main image if it's provided
    if (eventImage) {
        formData.append("main_image", eventImage);
    }

    // Append additional images to 'image_paths'
    additionalImages.forEach((image) => {
        formData.append("image_paths", image);
    });
  
    try {
      // Send PUT request to update the event
      const response = await axios.put(
        `https://digitalback.we-demo.xyz/event/update/${selectedEventId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      // Handle response
      console.log("Event updated successfully:", response.data);
      fetchData(); // Refresh event list
      setUpdateSuccessMessage("Event updated successfully");
      setTimeout(() => {
        setUpdateSuccessMessage("");
      }, 5000);
  
      // Reset form fields and state related to updating an event
      setUpdateEvent({ title: "", date: "", content: "", image_path: "" });
      setEventImage(null);
      setAdditionalImages([]);
      setSelectedEventId(null);
      // setShowUpdateForm(false); // Hide the update form
    } catch (error) {
      console.error("Error updating event:", error);
      setUpdateError("Error updating event");
      setTimeout(() => {
        setUpdateError("");
      }, 5000);
    }
  };
  
  
  
  

  const formatDateToReadableString = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (showUpdateForm) {
      // Assuming you want to focus on the 'title' input field when editing
      const titleInputField = document.getElementById('edit-event-title');
      if (titleInputField) {
        titleInputField.focus();
      }
    }
  }, [showUpdateForm]); 
  
  return (
    <>
    
      <div className="content">
      <Form onSubmit={handleSubmit}>
  <Row>
    <Col md="6">
      <FormGroup>
        <label>Title:</label>
        <Input
          type="text"
          name="title"
          value={newEvent.title}
          onChange={handleInputChange}
        />
      </FormGroup>
    </Col>
    <Col md="6">
      <FormGroup>
        <label>Arabic Title:</label>
        <Input
          type="text"
          name="title_ar"
          value={newEvent.title_ar}
          onChange={handleInputChange}
        />
      </FormGroup>
    </Col>
    <Col md="6">
      <FormGroup>
        <label>Date:</label>
        <Input
           type="date"
          name="date"
          value={newEvent.date}
          onChange={handleInputChange}
        />
      </FormGroup>
    </Col>
  </Row>
  <Row>
  <Col md="7">
              <FormGroup>
                <label htmlFor="content" className="textarea-label">Content:</label>
                <textarea
                   className="form-control"
                   id="content"
                   name="content"
                   value={newEvent.content}
                   onChange={handleContentChange}
                   style={{ minHeight: '300px' }}
                 ></textarea>
              </FormGroup>
            </Col>
  </Row>
  <Row>
  <Col md="7">
              <FormGroup>
                <label htmlFor="content" className="textarea-label">Arabic Content:</label>
                <textarea
                   className="form-control"
                   id="content_ar"
                   name="content_ar"
                   value={newEvent.content_ar}
                   onChange={handleContent_arChange}
                   style={{ minHeight: '300px' }}
                 ></textarea>
              </FormGroup>
            </Col>
  </Row>
  <Row>
  <Col md="12">
    <FormGroup>
      <label>Main Image:</label>
      <Input
        type="file"
        name="main_image"
        onChange={handleImageChange}
      />
    </FormGroup>
  </Col>
</Row>
<Row>
  <Col md="12">
    <FormGroup>
      <label>Additional Images:</label>
      <Input
        type="file"
        name="additional_images"
        onChange={handleImageChange}
        multiple
      />
    </FormGroup>
  </Col>
</Row>

  <Row>
    <Col md="12">
      <div className="update ml-auto mr-auto">
        <Button
          className="btn-round"
          color="primary"
          type="submit"
        >
          Add Event
        </Button>
        {addSuccessMessage && (
    <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
      {addSuccessMessage}
    </div>
  )}
  {AddRequired && (
  <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
    {AddRequired}
  </div>
)}

      </div>
    </Col>
  </Row>
</Form>
{addError && (
  <div style={{ color: 'red', marginBottom: '10px' }}>
    {addError}
  </div>
)}

<div className="content">
        {/* ... Your JSX code ... */}
        <CardFooter>
          <div>Total Events: {eventCount}</div>
        </CardFooter>
      </div>
   <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Event List</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                    <tr>
                <th>Title</th>
                <th>Arabic Title</th>
                <th>Date</th>
                <th>Content</th>
                <th>Arabic Content</th>
                <th>Main Image</th> {/* New column for main image */}
                <th>Additional Images</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
  {events.map((event) => (
    <tr key={event.id}>
      <td>{event.title}</td>
      <td>{event.title_ar}</td>
      <td>{formatDateToReadableString(event.date)}</td>
      <td>{event.content}</td>
      <td>{event.content_ar}</td>
      <td>
        {event.main_image && (
          <img src={`https://digitalback.we-demo.xyz/${event.main_image}`}
               alt={`Main image for ${event.title}`}
               style={{ maxWidth: '100px', marginRight: '10px' }} />
        )}
      </td>
      <td>
        {/* Display main image first if it exists, followed by additional images */}
        {[event.main_image, ...event.image_paths].filter((imagePath, index, self) => 
          imagePath && self.indexOf(imagePath) === index // Filter out duplicates and undefined values
        ).map((imagePath, index) => (
          <img key={index}
               src={`https://digitalback.we-demo.xyz/${imagePath}`}
               alt={`${event.title}-image-${index}`}
               style={{ maxWidth: '100px', marginRight: '10px' }} />
        ))}
      </td>
      <td>
        <Button color="success" onClick={() => handleUpdate(event)}>
          Update
        </Button>
      </td>
      <td>
        <Button
          color="danger"
          onClick={() => handleDelete(event.id, event.image_paths)}
          style={{ marginRight: '10px' }}
        >
          Delete
        </Button>
        </td>

    </tr>
  ))}
</tbody>

                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      {/* Display the update form when showUpdateForm is true */}
      {showUpdateForm && (
  <div className="content">
    <Form onSubmit={handleEventUpdate}>
      {/* Add your update form fields here */}
      <Row>
        <Col md="7">
          <FormGroup>
            <label>Title:</label>
            <Input
              type="text"
              name="title"
              id="edit-event-title"
              value={updateEvent.title}
              onChange={(e) =>
                setUpdateEvent({ ...updateEvent, title: e.target.value })
              }
            />
          </FormGroup>
        </Col>
        <Col md="7">
          <FormGroup>
            <label>Arabic Title:</label>
            <Input
              type="text"
              name="title_ar"
              id="edit-event-title_ar"
              value={updateEvent.title_ar}
              onChange={(e) =>
                setUpdateEvent({ ...updateEvent, title_ar: e.target.value })
              }
            />
          </FormGroup>
        </Col>
        <Col md="7">
          <FormGroup>
            <label>Date:</label>
            <Input
              type="text"
              name="date"
              value={updateEvent.date}                           
               onChange={(e) =>
                setUpdateEvent({ ...updateEvent, date: e.target.value })
              }
            />
          </FormGroup>
        </Col>
      </Row>
      
        <Col md="7">
          <FormGroup>
          <label htmlFor="content" className="textarea-label">Content:</label>
          <textarea
            className="form-control"
            id="content"
            name="content"
            value={updateEvent.content}
            onChange={handleContentChange}
            style={{ minHeight: '300px', width: '695px' }}
          ></textarea>
          </FormGroup>
        </Col>
        <Col md="7">
          <FormGroup>
          <label htmlFor="content" className="textarea-label">Arabic Content:</label>
          <textarea
            className="form-control"
            id="content_ar"
            name="content_ar"
            value={updateEvent.content_ar}
            onChange={handleContent_arChange}
            style={{ minHeight: '300px', width: '695px' }}
          ></textarea>
          </FormGroup>
        </Col>
        <Row>
        <Col md="7">
  <FormGroup>
    <label>Current Main Image:</label>
    {updateEvent.image_path && (
      <div>
        <img
          src={`https://digitalback.we-demo.xyz/${updateEvent.image_path}`}
          alt="Current Event"
          style={{ maxWidth: '200px', marginBottom: '10px' }}
        />
      </div>
    )}
    <label>New Main Image (optional):</label>
    <Input type="file" name="main_image" onChange={handleMainImageChange} />
  </FormGroup>
</Col>
<Col md="7">
  <FormGroup>
    <label>Current Additional Images:</label>
    <div>
      {additionalImages.map((imagePath, index) => (
        <img
          key={index}
          src={`https://digitalback.we-demo.xyz/${imagePath}`}
          alt={`Additional Image ${index + 1}`}
          style={{ maxWidth: '100px', marginRight: '10px', marginBottom: '10px' }}
        />
      ))}
    </div>
    <label>New Additional Images (optional):</label>
    <Input type="file" name="additional_images" onChange={handleAdditionalImagesChange} multiple />
  </FormGroup>
</Col>

      </Row>
      {/* Submit and Cancel buttons */}
      <Row>
        <Col md="12">
          <div className="update ml-auto mr-auto">
            <Button className="btn-round" color="primary" type="submit">
              Update Event
            </Button>{" "}
            <Button
              className="btn-round"
              color="secondary"
              onClick={() => {
                setShowUpdateForm(false);
                setSelectedEventId(null);
                setUpdateEvent({
                  title: "",
                  title_ar: "",
                  date: "",
                  content: "",
                  content_ar: "",
                  image_path: "",
                });
              }}
            >
              Cancel
            </Button>
            {updateError && (
              <div style={{ color: 'red', marginTop: '10px' }}>
                {updateError}
              </div>
            )}
            {updateSuccessMessage && (
              <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                {updateSuccessMessage}
              </div>
            )}
            {UpdateRequired && (
              <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                {UpdateRequired}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Form>
  </div>
)}
<Modal isOpen={showDeletePopup} toggle={() => setShowDeletePopup(false)}>
  <ModalHeader toggle={() => setShowDeletePopup(false)}>Confirm Delete</ModalHeader>
  <ModalBody>
    Are you sure you want to delete this event?
  </ModalBody>
  <ModalFooter>
    <Button color="danger" onClick={performDelete}>Delete</Button>{' '}
    <Button color="secondary" onClick={() => setShowDeletePopup(false)}>Cancel</Button>
  </ModalFooter>
</Modal>

    </>
  );
}

export default Events;