import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../assets/css/style.css';
import { Card, CardHeader, CardBody, CardFooter, CardTitle, Row, Col, Table, Form, FormGroup, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function WalkThrough() {
  const [aboutItems, setAboutItems] = useState([]);
  const [newAboutItem, setNewAboutItem] = useState({
    title: '',
    description: '',
    title_ar: '',
    description_ar: ''
  });
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [selectedAboutItemId, setSelectedAboutItemId] = useState(null);
  const [updateAboutItem, setUpdateAboutItem] = useState({
    title: '',
    description: '',
    title_ar: '',
    description_ar: '',
    image_path: ""
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [addSuccessMessage, setAddSuccessMessage] = useState("");
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState("");
  const [WalkThroughtItemCount, setWalkThroughtItemCount] = useState(0);
  const [addErrorMessage, setAddErrorMessage] = useState("");
  const [updateErrorMessage, setupdateErrorMessage] = useState("");
  const [imageErrorMessage, setimageErrorMessage] = useState("");
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [itemImage, setItemImage] = useState(null);
  const [addImageError, setAddImageError] = useState("");

  const isValidImageExtension = (fileName) => {
    const validExtensions = ['.webp', '.gif', '.png', '.jpg', '.jpeg'];
    const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase();
  
    return validExtensions.includes(`.${fileExtension}`);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAboutItem({ ...newAboutItem, [name]: value });
  };

  const handleImageChange = (e) => {
    const image = e.target.files[0];
    setItemImage(image);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Check if title or description is empty
    if (!newAboutItem.title.trim() || !newAboutItem.description.trim()|| !newAboutItem.title_ar.trim() || !newAboutItem.description_ar.trim()|| !itemImage)  {
      console.error('Both title and description are required.');
      setAddErrorMessage("Both title and description are required."); 
      setTimeout(() => {
      
        setAddErrorMessage(""); 
      }, 5000);
      return;
    }

    if (itemImage && !isValidImageExtension(itemImage.name)) {
      setimageErrorMessage("Invalid file extension. Please upload .WEBP, .GIF, .PNG, .JPG, or .JPEG");
      setTimeout(() => {
        setimageErrorMessage("");
      }, 5000);
      return;
    }
    setAddImageError("");
  
    try {

      const formData = new FormData();
      formData.append("image", itemImage);
      formData.append("title", newAboutItem.title);
      formData.append("description", newAboutItem.description);
      formData.append("title_ar", newAboutItem.title_ar);
      formData.append("description_ar", newAboutItem.description_ar);
      
      const response = await axios.post(
        'https://digitalback.we-demo.xyz/walk/add', formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      // handle response
      fetchData();
      setNewAboutItem({ title: '', description: '', title_ar: '', description_ar: '' }); // Reset form fields
      setItemImage(null);
      setAddSuccessMessage("About item added successfully");
      setTimeout(() => {
        setAddSuccessMessage("");
        setAddErrorMessage(""); // Reset error message
      }, 5000);
    } catch (error) {
      console.error('Error adding about item:', error);
      setAddErrorMessage("Error occurred while adding the item."); // Display error message
    }
  };
  const fetchData = async () => {
    try {
      const response = await axios.get('https://digitalback.we-demo.xyz/walk/list2');
      if (response.data && response.data.data) {
        setAboutItems(response.data.data);
        setWalkThroughtItemCount(response.data.data.length);
      } else {
        // Handle case where response does not have the expected structure
        console.error("Unexpected response structure:", response);
      }
      setLoading(false);
    } catch (error) {
      console.error(`Error getting about items: ${error}`);
      setError('Error fetching about items. Please try again later.');
      setLoading(false);
    }
  };
  
  const handleDelete = (id) => {
    setDeleteItemId(id);
    setShowDeletePopup(true);
  };
  

  const performDelete = async () => {
    try {
      const response = await axios.delete(`https://digitalback.we-demo.xyz/walk/delete/${deleteItemId}`);
      if (response.status === 200) {
        console.log('Item deleted successfully');
        fetchData();
      } else {
        console.error('Delete request failed:', response.status);
      }
    } catch (error) {
      console.error('Error deleting item:', error);
    }
    setShowDeletePopup(false);
  };
  

  const handleUpdate = (aboutItem) => {
    setSelectedAboutItemId(aboutItem.id);
    setUpdateAboutItem({
      title: aboutItem.title,
      title_ar: aboutItem.title_ar,
      description: aboutItem.description,
      description_ar: aboutItem.description_ar,
      image_path: aboutItem.image_path,
    });
    setShowUpdateForm(true);
  
    // Move the setTimeout function here
    setTimeout(() => {
      const updateInput = document.getElementById("updateTitleInput");
      if (updateInput) {
        updateInput.focus();
      }
    }, 0);
  };


  const handleAboutUpdate = async (e) => {
    e.preventDefault();
  
    // Validate input
    if (!updateAboutItem.title.trim() || !updateAboutItem.description.trim() || !updateAboutItem.title_ar.trim() || !updateAboutItem.description_ar.trim()) {
      setupdateErrorMessage("Both title and description are required for update.");
      setTimeout(() => {
        setupdateErrorMessage("");
      }, 5000);
      return;
    }
  
    // Initialize form data
    const formData = new FormData();
    formData.append("title", updateAboutItem.title);
    formData.append("title_ar", updateAboutItem.title_ar);
    formData.append("description", updateAboutItem.description);
    formData.append("description_ar", updateAboutItem.description_ar);
  
    // Add image to form data if it exists and is valid
    if (itemImage) {
      if (!isValidImageExtension(itemImage.name)) {
        setimageErrorMessage("Invalid file extension. Allowed: .WEBP, .GIF, .PNG, .JPG, .JPEG");
        setTimeout(() => {
          setimageErrorMessage("");
        }, 5000);
        return;
      }
      formData.append("image", itemImage);
    }
  
    try {
      // Send the update request
      const response = await axios.put(
        `https://digitalback.we-demo.xyz/walk/update/${selectedAboutItemId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      // Check the response
      if (response.status === 200) {
        setUpdateSuccessMessage("About item updated successfully");
        setTimeout(() => {
          setUpdateSuccessMessage("");
        }, 5000);
        // Update the list of items to reflect the change
        fetchData();
        // Reset the update form
        // setShowUpdateForm(false);
        setUpdateAboutItem({ title: '', title_ar: '', description: '', description_ar: '', image_path: "" });
        setSelectedAboutItemId(null);
      } else {
        throw new Error('Failed to update the item');
      }
    } catch (error) {
      console.error('Error updating about item:', error);
      setupdateErrorMessage("Error occurred while updating the item.");
    }
  };
  
  

  useEffect(() => {
    console.log("About Items:", aboutItems);
    fetchData();

  }, []); // Empty dependency array
  

  return (
    <>
<div className="content">
  <Form onSubmit={handleSubmit}>
  <CardFooter>
                  <div>Total Walk Through Items: {WalkThroughtItemCount}</div>
                </CardFooter>
    <Row>
      <Col md="7">
        <FormGroup>
          <label>Title:</label>
          <Input
            type="text"
            name="title"
            value={newAboutItem.title}
            onChange={handleInputChange}
          />
        </FormGroup>
      </Col>
      <Col md="7">
      <FormGroup>
  <label>Arabic Title:</label>
  <Input
    type="text"
    name="title_ar"
    value={newAboutItem.title_ar}
    onChange={handleInputChange}
  />
</FormGroup>
</Col>
      <Col md="7">
        <FormGroup>
          <label>Description:</label>
          <Input
            type="textarea"
            name="description"
            value={newAboutItem.description}
            onChange={handleInputChange}
          />
        </FormGroup>
      </Col>
      <Col md="7">
      <FormGroup>
  <label>Arabic Description:</label>
  <Input
    type="textarea"
    name="description_ar"
    value={newAboutItem.description_ar}
    onChange={handleInputChange}
  />
</FormGroup>
</Col>
      <Col md="12">
              <FormGroup>
                <label>Image:</label>
                <Input
                  type="file"
                  name="image"
                  onChange={handleImageChange}
                />
              </FormGroup>
            </Col>
      <Col md="12">
        <Button className="btn-round" color="primary" type="submit">
          Add About Item
        </Button>
        {addSuccessMessage && (
          <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
            {addSuccessMessage}
          </div>
        )}

{addErrorMessage && (
  <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
    {addErrorMessage}
  </div>
)}
{imageErrorMessage && (
 <div style={{ color: 'red' }}>
    {imageErrorMessage}
  </div>
)}

      </Col>
    </Row>
  </Form>

  <CardHeader>
    <CardTitle tag="h4">About List</CardTitle>
  </CardHeader>

  <CardBody>
    <Table responsive>
      <thead>
        <tr>
          <th>Title</th>
          <th>Arabic Title</th>
          <th>Description</th>
          <th>Arabic Description</th>
          <th>Image</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {aboutItems.map((item) => (
          <tr key={item.id}>
            <td>{item.title}</td>
            <td>{item.title_ar}</td>
            <td>{item.description}</td>
            <td>{item.description_ar}</td>
            <td>
            {item.image_path && (
  <img src={`https://digitalback.we-demo.xyz/uploads/${item.image_path}`} alt="Item" style={{ maxWidth: '200px', maxHeight: '200px' }} />
)}

</td>
            <td>

              <Button color="success" onClick={() => handleUpdate(item)}>
                Update
              </Button>
              {' '}
              <Button color="danger" onClick={() => handleDelete(item.id)}>
                Delete
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  </CardBody>

  {showUpdateForm && (
    <Form onSubmit={handleAboutUpdate}>
      <Row>
        <Col md="7">
          <FormGroup>
            <label>Title:</label>
            <Input
            type="text"
            name="title"
            id="updateTitleInput"  // Set the ID here
            value={updateAboutItem.title}
            onChange={(e) =>
              setUpdateAboutItem({ ...updateAboutItem, title: e.target.value })
            }
            />
          </FormGroup>
        </Col>
        <Col md="7">
          <FormGroup>
            <label>Arabic Title:</label>
            <Input
            type="text"
            name="Arabic title"
            id="updateTitleInput"  // Set the ID here
            value={updateAboutItem.title_ar}
            onChange={(e) =>
              setUpdateAboutItem({ ...updateAboutItem, title_ar: e.target.value })
            }
            />
          </FormGroup>
        </Col>
        <Col md="7">
          <FormGroup>
            <label>Description:</label>
            <Input
              type="textarea"
              name="description"
              value={updateAboutItem.description}
              onChange={(e) =>
                setUpdateAboutItem({ ...updateAboutItem, description: e.target.value })
              }
            />
          </FormGroup>
        </Col>
        <Col md="7">
          <FormGroup>
            <label>Arabic Description:</label>
            <Input
              type="textarea"
              name="Arabic description"
              value={updateAboutItem.description_ar}
              onChange={(e) =>
                setUpdateAboutItem({ ...updateAboutItem, description_ar: e.target.value })
              }
            />
          </FormGroup>
        </Col>
        <Col md="12">
          <FormGroup>
            <label>Current Image:</label>
            {updateAboutItem.image_path && (
              <div>
                <img
                  src={`https://digitalback.we-demo.xyz/uploads/${updateAboutItem.image_path}`}
                  alt="Item Image"
                  style={{ maxWidth: '200px', marginBottom: '10px' }}
                />
              </div>
            )}
            <label>Update Image (optional):</label>
            <Input
              type="file"
              name="image"
              id= "update-item-title"
              onChange={handleImageChange}
            />
          </FormGroup>
        </Col>
        <Col md="12">
          <Button className="btn-round" color="primary" type="submit">
            Update About Item
          </Button>
          <Button
            className="btn-round"
            color="secondary"
            onClick={() => setShowUpdateForm(false)}
          >
            Cancel
          </Button>
          {updateSuccessMessage && (
           <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
              {updateSuccessMessage}
            </div>
          )}
          {updateErrorMessage && (
  <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
    {updateErrorMessage}
  </div>
)}
{imageErrorMessage && (
 <div style={{ color: 'red' }}>
    {imageErrorMessage}
  </div>
)}
        </Col>
      </Row>
    </Form>
  )}
  <Modal isOpen={showDeletePopup} toggle={() => setShowDeletePopup(false)}>
  <ModalHeader toggle={() => setShowDeletePopup(false)}>Confirm Delete</ModalHeader>
  <ModalBody>
    Are you sure you want to delete this item?
  </ModalBody>
  <ModalFooter>
    <Button color="danger" onClick={() => performDelete()}>Delete</Button>{' '}
    <Button color="secondary" onClick={() => setShowDeletePopup(false)}>Cancel</Button>
  </ModalFooter>
</Modal>
</div>

    </>
  );
}

export default WalkThrough;
