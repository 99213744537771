import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  Input,
  Button,
} from "reactstrap";
import ReactQuill, { Quill } from 'react-quill';
import "react-quill/dist/quill.snow.css"; // Import styles

function About() {
  const [aboutItems, setAboutItems] = useState([]);
  const [editingAboutItem, setEditingAboutItem] = useState(null);
  const [aboutItemCount, setAboutItemCount] = useState(0);
  const [newImage, setNewImage] = useState(null);
  const [editingImage, setEditingImage] = useState(null);
  const [editorHtml, setEditorHtml] = useState("");
  const quillRef = useRef(null);
  const quillRefAr = useRef(null); 
  const [formInitialDisplay, setFormInitialDisplay] = useState(false);
  const [addSuccessMessage, setAddSuccessMessage] = useState("");
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState("");
  const [addErrorMessage, setAddErrorMessage] = useState("");
  const [updateErrorMessage, setUpdateErrorMessage] = useState("");







  const fetchAboutItems = async () => {
    try {
      const response = await axios.get(
        "https://digitalback.we-demo.xyz/about/list2"
      );
      if (response.data.success) {
        console.log("Fetched About Items:", response.data.data);
        setAboutItems(response.data.data);
        setAboutItemCount(response.data.data.length);
      } else {
        console.error("API request was not successful:", response.data.errors);
      }
    } catch (error) {
      console.error("Error fetching About items:", error);
    }
  };

  
  const addAboutItem = async () => {
    const title = document.getElementById("newAboutTitle").value.trim();
  
    // Check if title or description is empty
    if (!title || !editorHtml.trim()) {
      console.error('Both title and description are required.');
      setAddErrorMessage("Both title and description are required."); // Define this state variable
      return;
    }
  
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("description", editorHtml);
      formData.append("title_ar", editingAboutItem.title_ar);
      formData.append("description_ar", editingAboutItem.description_ar);

  
      await axios.post("https://digitalback.we-demo.xyz/about/add", formData);
      fetchAboutItems();
      document.getElementById("newAboutTitle").value = "";
      setEditorHtml("");
      setAddSuccessMessage("About item added successfully");
      setTimeout(() => {
        setAddSuccessMessage("");
        setAddErrorMessage(""); // Reset error message
      }, 5000);
    } catch (error) {
      console.error("Error adding About item:", error);
      setAddErrorMessage("Error occurred while adding the item."); // Display error message
    }
  };
  
  
  

  const handleEditingImageChange = (e) => {
    setEditingImage(e.target.files[0]);
  };

  const updateAboutItem = async () => {
    // Check if title or description is empty
    if (!editingAboutItem.title.trim() || !editingAboutItem.title_ar.trim() || !editingAboutItem.description.trim() || !editingAboutItem.description_ar.trim()) {
      setUpdateErrorMessage("Both title and description are required.");
      setTimeout(() => {
        setUpdateErrorMessage(""); // Clear the error message after a timeout
      }, 5000);
      return;
    }
    console.log("Updating About Item with Description:", editingAboutItem.description);
    try {
      const formData = new FormData();
      formData.append("title", editingAboutItem.title);
      formData.append("description", editingAboutItem.description);
      formData.append("title_ar", editingAboutItem.title_ar);
      formData.append("description_ar", editingAboutItem.description_ar);

  
      const response = await axios.put(`https://digitalback.we-demo.xyz/about/update/${editingAboutItem.id}`, formData);
  
      if (response.data.success) {
        fetchAboutItems();
        setEditingAboutItem(prevState => ({ ...prevState, title: "", title_ar: "", description: "", description_ar: "" })); // Reset only title and description
        setUpdateSuccessMessage("About item updated successfully");
        setTimeout(() => {
          setUpdateSuccessMessage("");
        }, 5000);
      } else {
        console.error("Failed to update About item:", response.data.errors);
      }
    } catch (error) {
      console.error("Error updating About item:", error);
    }
  };
  
  
  

  const deleteAboutItem = async (id) => {
    try {
      await axios.delete(`https://digitalback.we-demo.xyz/about/delete/${id}`);
      fetchAboutItems();
    } catch (error) {
      console.error("Error deleting About item:", error);
    }
  };
  const insertImage = (url) => {
    const editor = quillRef.current.getEditor();
    const range = editor.getSelection(true);
    editor.insertEmbed(range.index, 'image', url);
  };

  const selectLocalImage = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
  
    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append('image', file);
  
      try {
        const response = await axios.post('https://digitalback.we-demo.xyz/about/upload', formData);
        const imageUrl = response.data.imageUrl; // URL from server
        insertImage(imageUrl); // Insert the image URL into the editor
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    };
  };
  
  

  const modules = {
    toolbar: {
      container: [
        // Add other toolbar options as you need
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1'}, { 'outdent': '1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction
        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],
        ['clean'],                                        // remove formatting button
        ['link', 'image', 'video'],                       // link and image, video
      ],
      handlers: {
        'image': selectLocalImage // Handle image uploads
      }
    }
  };

  function isBase64Image(str) {
    return str.startsWith('data:image');
  }
  
  useEffect(() => {
    fetchAboutItems();
    // Focus on the title input field when the component mounts
    const titleInputField = document.getElementById("newAboutTitle");
    if (titleInputField) {
      console.log("First item's description:", aboutItems[0].description);
      titleInputField.focus();
    }
  }, []);

  useEffect(() => {
    // Focus on the title input field when editing an about item
    if (editingAboutItem && !formInitialDisplay) {
      setFormInitialDisplay(true);
      const updateTitleInputField = document.getElementById("editAboutTitle");
      if (updateTitleInputField) {
        updateTitleInputField.focus();
      }
    }else if (!editingAboutItem && formInitialDisplay) {
      setFormInitialDisplay(false); // Reset when the form is closed
    }
  }, [editingAboutItem]);

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">About Items</CardTitle>
              </CardHeader>
              <CardBody>
                {/* <Form>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label>Title:</label>
                        <Input type="text" 
                        name="title"
                         id="newAboutTitle" />
                      </FormGroup>
                    </Col>
                    <Col md="7">
                      <FormGroup>
                        <label for="content" className="textarea-label">
                          Description:
                        </label>
                        <div
                          style={{
                            height: '300px', // Adjust the height as needed
                            overflowY: 'auto', // Add a vertical scrollbar when content overflows
                          }}
                        >
                          <ReactQuill
                          ref={quillRef}
                            value={editorHtml}
                            onChange={setEditorHtml}
                            modules={{
                              toolbar: [
                                [{ header: "1" }, { header: "2" }, { font: [] }],
                                [{ list: "ordered" }, { list: "bullet" }],
                                ["bold", "italic", "underline"],
                                ["image"],
                              ],
                            }}
                            style={{ height: '80%' }} // Set the height of ReactQuill to 100%
                          />
                        </div>
                      </FormGroup>
                    </Col>

                    
                    <Col md="8">
                      <Button
                        className="btn-round"
                        color="primary"
                        onClick={addAboutItem}
                      >
                        Add About Item
                      </Button>
                      {addSuccessMessage && (
    <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
      {addSuccessMessage}
    </div>
  )}
  {addErrorMessage && (
  <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
    {addErrorMessage}
  </div>
)}

                    </Col>
                  </Row>
                </Form> */}
                <CardFooter>
                  <div>Total About Items: {aboutItemCount}</div>
                </CardFooter>
                <Table responsive>
                <thead className="text-primary">
                      <tr>
                        <th>Title</th>
                        <th>Arabic Title</th>
                        <th>Description</th>
                        <th>Arabic Description</th>
                        <th>Actions</th>

                      </tr>
                    </thead>
                    <tbody>
                {aboutItems.map((item) => (
                  <tr key={item.id}>
                    <td>{item.title}</td>
                    <td>{item.title_ar}</td>
                    <td dangerouslySetInnerHTML={{ __html: item.description }}></td>
                    <td dangerouslySetInnerHTML={{ __html: item.description_ar }}></td>
                    <td>
                          <Button
                            color="success"
                            onClick={() => setEditingAboutItem(item)}
                          >
                            Update
                          </Button>{" "}
                          {/* <Button
                            color="danger"
                            onClick={() => deleteAboutItem(item.id)}
                          >
                            Delete
                          </Button> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
              {editingAboutItem && (
                <CardFooter>
                  <Form>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label>Update Title:</label>
                          <Input
                            type="text"
                            name="title"
                            id="editAboutTitle"
                            value={editingAboutItem ? editingAboutItem.title : ''}
                            onChange={(e) => setEditingAboutItem({ ...editingAboutItem, title: e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label>Update Arabic Title:</label>
                          <Input
                            type="text"
                            name="title_ar"
                            id="editAboutTitle"
                            value={editingAboutItem ? editingAboutItem.title_ar : ''}
                            onChange={(e) => setEditingAboutItem({ ...editingAboutItem, title_ar: e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="7">
                        <FormGroup>
                          <label htmlFor="updateContent" className="textarea-label">
                            Update Description:
                          </label>
                          <div
                            style={{
                              height: '300px', // Adjust the height as needed
                              overflowY: 'auto', // Add a vertical scrollbar when content overflows
                            }}
                          >
                            <ReactQuill
                              ref={quillRef}
                              value={editingAboutItem ? editingAboutItem.description : ''}
                              onChange={(html) => 
                                setEditingAboutItem({
                                  ...editingAboutItem,
                                  description: html
                                })
                              }
                              modules={{
                                toolbar: [
                                  [{ header: "1" }, { header: "2" }, { font: [] }],
                                  [{ list: "ordered" }, { list: "bullet" }],
                                  ["bold", "italic", "underline"],
                                  ["image"],
                                ],
                              }}
                              style={{ height: '80%' }} // Set the height of ReactQuill to 80%
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="7">
  <FormGroup>
    <label htmlFor="updateArabicContent" className="textarea-label">
      Update Arabic Description:
    </label>
    <div
      style={{
        height: '300px', // Adjust the height as needed
        overflowY: 'auto', // Add a vertical scrollbar when content overflows
      }}
    >
<ReactQuill
  ref={quillRefAr} // Assuming you have a separate ref for the Arabic editor
  value={editingAboutItem ? editingAboutItem.description_ar : ''}
  onChange={(htmlAr) => {
    if (htmlAr !== editingAboutItem.description_ar) {
      setEditingAboutItem({
        ...editingAboutItem,
        description_ar: htmlAr
      });
    }
  }}
  modules={{
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline"],
      ["image"],
      // Add any other toolbar options you need
    ],
  }}
  style={{ height: '80%' }} // Set the height of ReactQuill to 80%
/>



    </div>
  </FormGroup>
</Col>


                      <Col md="12">
                        <Button
                          className="btn-round"
                          color="info"
                          onClick={updateAboutItem}
                        >
                          Update About Item
                        </Button>{" "}
                        <Button
                          className="btn-round"
                          color="secondary"
                          onClick={() => setEditingAboutItem(null)}
                        >
                          Cancel
                        </Button>
                        {updateSuccessMessage && (
        <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
          {updateSuccessMessage}
        </div>
      )}
      {updateErrorMessage && (
  <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
    {updateErrorMessage}
  </div>
)}

                      </Col>
                    </Row>
                  </Form>
                </CardFooter>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default About;
