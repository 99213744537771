import React, { useEffect, useState, Fragment  } from "react";
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  Input,
  Button,
} from "reactstrap";
import "../assets/css/style.css"; 
import { Collapse } from 'reactstrap';


function QRManagement() {
  const [qrcodes, setQRcodes] = useState([]); // Initialize qrcodes as an empty array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newQR, setNewQR] = useState({
    title: "",
    description: "",
    qr_code_url: "",
    name: "",
    title_ar: "",
    description_ar: "",
    name_ar: "",
    image: null,
    audio: null,
    video: null,
    logo: null,
  
  });

  const [qrImage, setQRImage] = useState(null);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [selectedQRId, setSelectedQRId] = useState(null);
  const [newImage, setNewImage] = useState(null);


  const [updateQR, setUpdateQR] = useState({
    title: "",
    description: "",
    qr_code_url: "",
    name: "",
    title_ar: "",
    description_ar: "",
    name_ar: "",
    image: null,
    audio: null,
    video: null,
    logo: null,
  });

  const [qrCodeCount, setQRCodeCount] = useState(0);
  const [imageError, setImageError] = useState("");
  const [audioError, setAudioError] = useState("");
  const [videoError, setVideoError] = useState("");
  const [logoError, setLogoError] = useState("");
  const [updateQRSuccessMessage, setUpdateQRSuccessMessage] = useState("");
  const [updateError, setUpdateError] = useState({
    titleError: "",
    descriptionError: "",
    nameError: ""
  });
  const [expandedDescriptionId, setExpandedDescriptionId] = useState(null);



  const toggleDescription = (qrId) => {
    setExpandedDescriptionId(
      expandedDescriptionId === qrId ? null : qrId
    );
  };
  

  

  const isValidImageExtension = (fileName) => {
    const validExtensions = ['.webp', '.gif', '.png', '.jpg', '.jpeg'];
    return validExtensions.includes(`.${fileName.split('.').pop().toLowerCase()}`);
  };

  const isValidAudioExtension = (fileName) => {
    const validExtensions = ['.alac', '.wma', '.m4a', '.ogg', '.flac', '.aac', '.mp3', '.wav'];
    return validExtensions.includes(`.${fileName.split('.').pop().toLowerCase()}`);
};

  const isValidVideoExtension = (fileName) => {
    const validExtensions = ['.ogg', '3gp', '.mpeg', '.mpg', '.m4v', '.webm', '.flv', '.wmv', '.avi', '.mov', '.mp4'];
    return validExtensions.includes(`.${fileName.split('.').pop().toLowerCase()}`);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewQR({ ...newQR, [name]: value });
  };

  const handleFileChange = (e, fieldName, isUpdate = false) => {
    const file = e.target.files[0];
    if (!file) return;
  
    let isValidFile = false;
    // Validate file extensions
    switch (fieldName) {
      case 'image':
      case 'logo':
        isValidFile = isValidImageExtension(file.name);
        if (!isValidFile) {
          setImageError(`Invalid file extension for ${fieldName}. Allowed: .WEBP, .GIF, .PNG, .JPG, .JPEG`);
        }
        break;
      case 'audio':
        isValidFile = isValidAudioExtension(file.name);
        if (!isValidFile) {
          setAudioError("Invalid file extension for audio. Allowed: .alac, .wma, .m4a, .ogg, .flac, .aac, .mp3, .wav");
        }
        break;
      case 'video':
        isValidFile = isValidVideoExtension(file.name);
        if (!isValidFile) {
          setVideoError("Invalid file extension for video. Allowed: .ogg, .3gp, .mpeg, .mpg, .m4v, .webm, .flv, .wmv, .avi, .mov, .mp4");
        }
        break;
      default:
        break;
    }
  
    if (isValidFile) {
      if (isUpdate) {
        setUpdateQR(prevState => ({ ...prevState, [fieldName]: file }));
      } else {
        setNewQR(prevState => ({ ...prevState, [fieldName]: file }));
      }
      // Clear any error messages
      setImageError("");
      setAudioError("");
      setVideoError("");
      setLogoError("");
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Create FormData instance
    const formData = new FormData();
    formData.append("title", newQR.title);
    formData.append("description", newQR.description);
    formData.append("qr_code_url", newQR.qr_code_url);
    formData.append("name", newQR.name);
    formData.append("title_ar", newQR.title_ar);
    formData.append("description_ar", newQR.description_ar);
    formData.append("name_ar", newQR.name_ar);
    
  
    // Append files only if they exist
    if (newQR.image) formData.append("image", newQR.image);
    if (newQR.audio) formData.append("audio", newQR.audio);
    if (newQR.video) formData.append("video", newQR.video);
    if (newQR.logo) formData.append("logo", newQR.logo);
  
    try {
      const response = await axios.post("https://digitalback.we-demo.xyz/qr/add", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("QR code added successfully:", response.data);
      fetchData();
      setNewQR({
        title: "",
        description: "",
        qr_code_url: "",
        name: "",
        title_ar: "",
        description_ar: "",
        name_ar: "",
        image: null,
        audio: null,
        video: null,
        logo: null,
      });
      setQRImage(null);
    } catch (error) {
      console.error("Error adding QR code:", error);
    }
  }

   const fetchData = async () => {
    try {
      const response = await axios.get("https://digitalback.we-demo.xyz/qr/list");
      console.log("Fetched QR Codes:", response.data.data); // Log fetched data
      if (response.status === 200) {
        setQRcodes(response.data.data); // Update qrcodes with the fetched data
        setQRCodeCount(response.data.data.length); // Set the QR code count
        setLoading(false);
        setError(null);
      } else {
        setError("API returned an error status: " + response.status);
      }
    } catch (error) {
      console.error(`Error getting data: ${error}`);
      setLoading(false);
      setError("Error fetching data. Please try again later.");
    }
  };
  


  const handleUpdate = (qr) => {
    setSelectedQRId(qr.id);
    setUpdateQR({
      title: qr.title,
      description: qr.description,
      qr_code_url: qr.qr_code_url,
      name: qr.name,
      title_ar: qr.title_ar,
      description_ar: qr.description_ar,
      name_ar: qr.name_ar,      
      image: qr.image,
      audio: qr.audio ,
      video: qr.video ,
      logo: qr.logo ,
    });
    setShowUpdateForm(true);
    
  

  // Create URLs for image, audio, video, and logo for preview
  if (qr.image) {
    fetch(qr.image)
      .then((response) => response.blob())
      .then((data) => {
        const imageUrl = URL.createObjectURL(data);
        setUpdateQR((prevUpdateQR) => ({
          ...prevUpdateQR,
          image: imageUrl,
        }));
      });
  }

  if (qr.audio) {
    fetch(qr.audio)
      .then((response) => response.blob())
      .then((data) => {
        const audioUrl = URL.createObjectURL(data);
        setUpdateQR((prevUpdateQR) => ({
          ...prevUpdateQR,
          audio: audioUrl,
        }));
      });
  }

  if (qr.video) {
    fetch(qr.video)
      .then((response) => response.blob())
      .then((data) => {
        const videoUrl = URL.createObjectURL(data);
        setUpdateQR((prevUpdateQR) => ({
          ...prevUpdateQR,
          video: videoUrl,
        }));
      });
  }

  if (qr.logo) {
    fetch(qr.logo)
      .then((response) => response.blob())
      .then((data) => {
        const logoUrl = URL.createObjectURL(data);
        setUpdateQR((prevUpdateQR) => ({
          ...prevUpdateQR,
          logo: logoUrl,
        }));
      });
  }
};

const handleQRUpdate = async (e) => {
    e.preventDefault();

      // Check for extension errors
  if (imageError || audioError || videoError || logoError) {
    return; // Stop the update process if there are file extension errors
  }

    // Reset error states
    setUpdateError({
        titleError: "",
        descriptionError: "",
        nameError: "",
        generalError: ""
    });
    setImageError("");
    setAudioError("");
    setVideoError("");
    setLogoError("");

    let hasError = false;

    // Validate text fields
    if (!updateQR.title.trim()) {
        setUpdateError(prev => ({ ...prev, titleError: "Title is required." }));
        hasError = true;
        setTimeout(() => setUpdateError(prev => ({ ...prev, titleError: "" })), 5000);
    }
    if (!updateQR.description.trim()) {
        setUpdateError(prev => ({ ...prev, descriptionError: "Description is required." }));
        hasError = true;
        setTimeout(() => setUpdateError(prev => ({ ...prev, descriptionError: "" })), 5000);
    }
    if (!updateQR.name.trim()) {
        setUpdateError(prev => ({ ...prev, nameError: "Name is required." }));
        hasError = true;
        setTimeout(() => setUpdateError(prev => ({ ...prev, nameError: "" })), 5000);
    }
    if (!updateQR.title_ar.trim()) {
      setUpdateError(prev => ({ ...prev, titleError: "Arabic Title is required." }));
      hasError = true;
      setTimeout(() => setUpdateError(prev => ({ ...prev, titleError: "" })), 5000);
  }
  if (!updateQR.description_ar.trim()) {
      setUpdateError(prev => ({ ...prev, descriptionError: "Arabic Description is required." }));
      hasError = true;
      setTimeout(() => setUpdateError(prev => ({ ...prev, descriptionError: "" })), 5000);
  }
  if (!updateQR.name_ar.trim()) {
      setUpdateError(prev => ({ ...prev, nameError: "Arabic Name is required." }));
      hasError = true;
      setTimeout(() => setUpdateError(prev => ({ ...prev, nameError: "" })), 5000);
  }

    // Validate file extensions for new file uploads
    if (updateQR.image instanceof File && !isValidImageExtension(updateQR.image.name)) {
        setImageError("Invalid file extension for image. Allowed: .WEBP, .GIF, .PNG, .JPG, .JPEG");
        hasError = true;
    }
    if (updateQR.logo instanceof File && !isValidImageExtension(updateQR.logo.name)) {
        setLogoError("Invalid file extension for logo. Allowed: .WEBP, .GIF, .PNG, .JPG, .JPEG");
        hasError = true;
    }
    if (updateQR.audio instanceof File && !isValidAudioExtension(updateQR.audio.name)) {
        setAudioError("Invalid file extension for audio. Allowed: .alac, .wma, .m4a, .ogg, .flac, .aac, .mp3, .wav");
        hasError = true;
    }
    if (updateQR.video instanceof File && !isValidVideoExtension(updateQR.video.name)) {
        setVideoError("Invalid file extension for video. Allowed: .ogg, .3gp, .mpeg, .mpg, .m4v, .webm, .flv, .wmv, .avi, .mov, .mp4");
        hasError = true;
    }

    if (hasError) return;

    // Create FormData for the update request
    const formData = new FormData();
    formData.append("title", updateQR.title);
    formData.append("description", updateQR.description);
    formData.append("qr_code_url", updateQR.qr_code_url);
    formData.append("name", updateQR.name);
    formData.append("title_ar", updateQR.title_ar);
    formData.append("description_ar", updateQR.description_ar);
    formData.append("name_ar", updateQR.name_ar);
    

    // Append new files only if they exist and have valid extensions
    if (updateQR.image instanceof File) formData.append("image", updateQR.image);
    if (updateQR.audio instanceof File) formData.append("audio", updateQR.audio);
    if (updateQR.video instanceof File) formData.append("video", updateQR.video);
    if (updateQR.logo instanceof File) formData.append("logo", updateQR.logo);

    try {
        const response = await axios.put(
            `https://digitalback.we-demo.xyz/qr/update/${selectedQRId}`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );

        // Handle the response
        if (response.status === 200) {
            console.log("QR code updated successfully:", response.data);
             if (updateQR.video || updateQR.audio) {
                window.location.reload();
            }
            fetchData();
            setUpdateQRSuccessMessage("QR code updated successfully");
            setTimeout(() => {
                setUpdateQRSuccessMessage("");
            }, 5000);

            // Reset the update form fields
            setUpdateQR({
                title: "",
                description: "",
                qr_code_url: "",
                name: "",
                title_ar: "",
                description_ar: "",
                name_ar: "",
                image: null,
                audio: null,
                video: null,
                logo: null,
            });
        } else {
            console.error("Update request failed with status:", response.status);
            setUpdateError({ ...updateError, generalError: "Failed to update QR code." });
        }
    } catch (error) {
        console.error("Error updating QR code:", error);
        setUpdateError({ ...updateError, generalError: "An error occurred while updating the QR code." });
    }
};




  


useEffect(() => {
  fetchData();
  // Focus on the title input field when the component mounts
  const titleInputField = document.getElementById('newQRTitle');
  if (titleInputField) {
    titleInputField.focus();
  }
}, []);

useEffect(() => {
  // Focus on the title input field when editing a QR code
  if (showUpdateForm) {
    const updateTitleInputField = document.getElementById('updateQRTitle');
    if (updateTitleInputField) {
      updateTitleInputField.focus();
    }
  }
}, [showUpdateForm]);

  return (
    <>
    <div className="content">
      <Card>
        <CardHeader>
          <CardTitle tag="h4">QR Code List</CardTitle>
        </CardHeader>
        <CardFooter>
          <div>Total QR Codes: {qrCodeCount}</div>
        </CardFooter>
        <CardBody>
        <Table responsive>
  <thead className="text-primary">
    <tr>
      <th style={{ width: '100px' }}>Name</th>
      <th style={{ width: '100px' }}>Arabic Name</th>
      <th style={{ width: '80px' }}>Title</th>
      <th style={{ width: '80px' }}>Arabic Title</th>
      <th style={{ width: '200px' }}>Description</th>
      <th style={{ width: '200px' }}>Arabic Description</th>
      <th style={{ width: '100px' }}>Image</th>
      <th style={{ width: '100px' }}>Audio</th>
      <th style={{ width: '100px' }}>Video</th>
      <th style={{ width: '100px' }}>Logo</th>
      <th style={{ width: '100px' }}>Actions</th>
    </tr>
  </thead>
  <tbody>
    {qrcodes.map((qr) => (
      <Fragment key={qr.id}>
        <tr key={qr.id}>
          <td style={{ width: '100px' }}>{qr.name}</td>
          <td style={{ width: '100px' }}>{qr.name_ar || "No Arabic Name"}</td>
          <td style={{ width: '80px' }}>{qr.title}</td>
          <td style={{ width: '80px' }}>{qr.title_ar || "No Arabic Title"}</td>
          <td style={{ width: '200px' }}>
            {qr.description.length > 30 ? (
              <>
                {qr.description.slice(0, 30)}{' '}
                <Button
                  color="primary"
                  onClick={() => toggleDescription(qr.id)}
                  className="btn-sm mt-2"
                  style={{ fontSize: '9px' }}
                >
                  Read More
                </Button>
              </>
            ) : (
              qr.description
            )}
          </td>
          <td style={{ width: '200px' }}>
  {qr.description_ar ? (
    qr.description_ar.length > 30 ? (
      <>
        {qr.description_ar.slice(0, 30)}{' '}
        <Button
          color="primary"
          onClick={() => toggleDescription(qr.id)}
          className="btn-sm mt-2"
          style={{ fontSize: '9px' }}
        >
          Read More
        </Button>
      </>
    ) : (
      qr.description_ar
    )
  ) : "No Arabic Description"}
</td>

          <td style={{ width: '100px' }}>
            {/* Displaying the image */}
            {qr.image && (
              <img
                src={`https://digitalback.we-demo.xyz${qr.image}`} // Adjust the base URL if necessary
                alt="QR Code"
                style={{ maxWidth: "100%" }} // You can set the size as per your requirement
              />
            )}
          </td>
          <td style={{ width: '100px' }}>
            {/* Displaying the audio */}
            {qr.audio && (
              <audio controls>
                <source src={`https://digitalback.we-demo.xyz${qr.audio}`} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            )}
          </td>
          <td style={{ width: '100px' }}>
            {/* Displaying the video */}
            {qr.video && (
              <video controls width="100%">
                <source src={`https://digitalback.we-demo.xyz${qr.video}`} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </td>
          <td style={{ width: '100px' }}>
            {qr.logo && (
              <img
                src={`https://digitalback.we-demo.xyz${qr.logo}`} // Adjust the base URL if necessary
                alt="Logo"
                style={{ maxWidth: "100%" }} // Adjust the size as required
              />
            )}
          </td>
          <td style={{ width: '100px' }}>
            <Button color="success" onClick={() => handleUpdate(qr)}>Update</Button>
          </td>
        </tr>
        {expandedDescriptionId === qr.id && (
          <tr>
            <td colSpan="8">
              <div>
                <p>{qr.description}</p>
                {/* Add other details here */}
              </div>
            </td>
          </tr>
        )}
          {expandedDescriptionId === qr.id && (
          <tr>
            <td colSpan="8">
              <div>
                <p>{qr.description_ar}</p>
                {/* Add other details here */}
              </div>
            </td>
          </tr>
        )}
      </Fragment>
    ))}
  </tbody>
</Table>

          </CardBody>
        </Card>
      </div>

      {showUpdateForm && (
        <div className="content">
         <Form onSubmit={handleQRUpdate}>
  <Row>
    <Col md="6">
      <FormGroup>
        <label>Title:</label>
        <Input
          type="text"
          name="title"
          id="updateQRTitle"
          value={updateQR.title}
          onChange={(e) =>
            setUpdateQR({ ...updateQR, title: e.target.value })
          }
          dir="rtl"
        />
      </FormGroup>
    </Col>
    <Col md="6">
      <FormGroup>
        <label>Arabic Title:</label>
        <Input
          type="text"
          name="Arabic title"
          id="updateQRTitle"
          value={updateQR.title_ar}
          onChange={(e) =>
            setUpdateQR({ ...updateQR, title_ar: e.target.value })
          }
          dir="rtl"
        />
      </FormGroup>
    </Col>
    <Col md="12">
    <FormGroup>
  <label>Description:</label><br />
  <textarea
    name="description"
    value={updateQR.description}
    onChange={(e) =>
      setUpdateQR({ ...updateQR, description: e.target.value })
    }
    style={{
      width: '560px',
    }}
    dir="rtl"
  ></textarea>
</FormGroup>
    </Col>
    <Col md="12">
    <FormGroup>
  <label>Arabic Description:</label><br />
  <textarea
    name="Arabic description"
    value={updateQR.description_ar}
    onChange={(e) =>
      setUpdateQR({ ...updateQR, description_ar: e.target.value })
    }
    style={{
      width: '560px',
    }}
    dir="rtl"
  ></textarea>
</FormGroup>
    </Col>
  </Row>
  <Row>
    
    <Col md="6">
      <FormGroup>
        <label>Name:</label>
        <Input
          type="text"
          name="name"
          value={updateQR.name}
          onChange={(e) =>
            setUpdateQR({ ...updateQR, name: e.target.value })
          }
          dir="rtl"
        />
      </FormGroup>
    </Col>
    <Col md="6">
      <FormGroup>
        <label>Arabic Name:</label>
        <Input
          type="text"
          name="Arabic name"
          value={updateQR.name_ar}
          onChange={(e) =>
            setUpdateQR({ ...updateQR, name_ar: e.target.value })
          }
          dir="rtl"
        />
      </FormGroup>
    </Col>
  </Row>
  <Row>
  <Col md="6">
  <FormGroup>
  <label>Image:</label>
  {updateQR.image && (
    <div>
      <img
        src={updateQR.image}
        alt="QR Image"
        style={{ maxWidth: '200px', marginBottom: '10px' }}
      />
    </div>
  )}
 
  <Input type="file" name="image" onChange={(e) => handleFileChange(e, "image", true)} />
</FormGroup>
  </Col>
    <Col md="6">
      <FormGroup>
        <label>Audio:</label>
        <Input type="file" name="audio" onChange={(e) => handleFileChange(e, "audio", true)} />
      
      </FormGroup>
    </Col>
  </Row>
  <Row>
    <Col md="6">
      <FormGroup>
        <label>Video:</label>
        <Input type="file" name="video" onChange={(e) => handleFileChange(e, "video", true)} />

      </FormGroup>
    </Col>
    <Col md="6">
      <FormGroup>
        <label>Logo:</label>
        <Input type="file" name="logo" onChange={(e) => handleFileChange(e, "logo", true)} />

      </FormGroup>
    </Col>
  </Row>
  <Row>
    <Col md="12">
      <div className="update ml-auto mr-auto">
        <Button className="btn-round" color="primary" type="submit">
          Update QR Code
        </Button>{" "}
            <Button
              className="btn-round"
              color="secondary"
              onClick={() => {
                setShowUpdateForm(false);
                setSelectedQRId(null);
                setUpdateQR({
                  title: "",
                  description: "",
                  qr_code_url: "",
                  name: "",
                  title_ar: "",
                  description_ar: "",
                  name_ar: "",
                  image: null,
                  audio: null,
                  video: null,
                  logo: null,
                });

              }}
            >
              Cancel
            </Button>
            {imageError && <div style={{ color: 'red' }}>{imageError}</div>}
        {logoError && <div style={{ color: 'red' }}>{logoError}</div>}
        {audioError && <div style={{ color: 'red' }}>{audioError}</div>}
        {videoError && <div style={{ color: 'red' }}>{videoError}</div>}
        {updateQRSuccessMessage && (
       <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
          {updateQRSuccessMessage}
        </div>
      )}
  {updateError.titleError && (
  <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
    {updateError.titleError}
  </div>
)}
{updateError.descriptionError && (
  <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
    {updateError.descriptionError}
  </div>
)}
{updateError.nameError && (
  <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
    {updateError.nameError}
  </div>
)}

      
      </div>
    </Col>
  </Row>
</Form>
        </div>
      )}
    </>
  );
}

export default QRManagement;
