import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  Input,
  Button,Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

function HomePage() {
  const [backgrounds, setBackgrounds] = useState([]);
  const [editingBackground, setEditingBackground] = useState(null);
  const [backgroundCount, setBackgroundCount] = useState(0);
  const [newImage, setNewImage] = useState(null);
  const [newIcon, setNewIcon] = useState(null); 
  const [addError, setAddError] = useState(""); 
  const [addMessageError, setaddMessageError] = useState("");
  const [updateMessageError, setupdateMessageError] = useState("");
  const [updateError, setUpdateError] = useState(""); // State for update error message
  const [hasFocused, setHasFocused] = useState(false);
  const [addSuccessMessage, setAddSuccessMessage] = useState("");
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState("");
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteBackgroundId, setDeleteBackgroundId] = useState(null);



  // Function to check if the image extension is valid
  const isValidImageExtension = (fileName) => {
    const validExtensions = ['.webp', '.gif', '.png', '.jpg', '.jpeg'];
    const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase();
    return validExtensions.includes(`.${fileExtension}`);
  };

  const fetchBackgrounds = async () => {
    try {
      const response = await axios.get("https://digitalback.we-demo.xyz/background/list");
      if (response.data.success) {
        setBackgrounds(response.data.data);
        setBackgroundCount(response.data.data.length);
      } else {
        console.error("API request was not successful:", response.data.errors);
      }
    } catch (error) {
      console.error("Error fetching backgrounds:", error);
    }
  };

  const addBackground = async () => {
    const name = document.getElementById("newBackgroundName").value.trim();
    const text = document.getElementById("newBackgroundText").value.trim();
    const imageFile = document.getElementById("newBackgroundImage").files[0];
    const iconFile = document.getElementById("newBackgroundIcon").files[0];
  
    // Check if all fields are filled
    if (!name || !text || !imageFile || !iconFile) {
      setaddMessageError("All fields are required.");
      setTimeout(() => {
        setaddMessageError("");
      }, 5000);
      return;
    }
  

    // Validate image and icon extensions
    if ((imageFile && !isValidImageExtension(imageFile.name)) ||
        (iconFile && !isValidImageExtension(iconFile.name))) {
        setAddError("Invalid file extension. Please upload .WEBP, .GIF, .PNG, .JPG, or .JPEG");
        setTimeout(() => {
          setAddError("");
        }, 5000);
        return;
    }
    setAddError(""); // Clear the error message

    try {
        const formData = new FormData();
        formData.append("name", document.getElementById("newBackgroundName").value);
        formData.append("text", document.getElementById("newBackgroundText").value);
        formData.append("image_path", imageFile);
        formData.append("icon_path", iconFile);

        await axios.post("https://digitalback.we-demo.xyz/background/add", formData);
        fetchBackgrounds();
        setAddSuccessMessage("Background added successfully");
        setTimeout(() => {
          setAddSuccessMessage("");
        }, 5000);
        document.getElementById("newBackgroundName").value = "";
        document.getElementById("newBackgroundText").value = "";
        document.getElementById("newBackgroundImage").value = "";
        document.getElementById("newBackgroundIcon").value = "";
    } catch (error) {
        console.error("Error adding background:", error);
    }
};


const updateBackground = async (id) => {
  const formData = new FormData();
  let isFieldUpdated = false;

  // Validation for name and text
  if (!editingBackground.name.trim() || !editingBackground.name_ar.trim() || !editingBackground.text.trim() || !editingBackground.text_ar.trim()) {
    setupdateMessageError("Name and Text fields are required.");
    setTimeout(() => {
      setupdateMessageError("");
    }, 5000);
    return;
  }

  // Append name and text to formData
  formData.append("name", editingBackground.name);
  formData.append("name_ar", editingBackground.name_ar);
  formData.append("text", editingBackground.text);
  formData.append("text_ar", editingBackground.text_ar);

  // Append new image if provided
  if (newImage) {
    if (!isValidImageExtension(newImage.name)) {
      setUpdateError("Invalid file extension for image. Allowed: .WEBP, .GIF, .PNG, .JPG, .JPEG");
      setTimeout(() => setUpdateError(""), 5000);
      return;
    }
    formData.append("image_path", newImage);
    isFieldUpdated = true;
  }

  // Append new icon if provided
  if (newIcon) {
    if (!isValidImageExtension(newIcon.name)) {
      setUpdateError("Invalid file extension for icon. Allowed: .WEBP, .GIF, .PNG, .JPG, .JPEG");
      setTimeout(() => setUpdateError(""), 5000);
      return;
    }
    formData.append("icon_path", newIcon);
    isFieldUpdated = true;
  }

  // Make the update request
  try {
    const response = await axios.put(`https://digitalback.we-demo.xyz/background/update/${id}`, formData);
    if (response.data.success) {
      fetchBackgrounds();
      setEditingBackground({ name: "", name_ar: "", text: "", text_ar: "" });
      setNewImage(null);
      setNewIcon(null);
      setUpdateSuccessMessage("HomePage updated successfully");
      setTimeout(() => {
        setUpdateSuccessMessage("");
      }, 5000);
    } else {
      console.error("Failed to update background:", response.data.errors);
      setUpdateError(response.data.errors);
    }
  } catch (error) {
    console.error("Error updating background:", error);
    setUpdateError("Error occurred while updating");
  }
};




  
const deleteBackground = (id) => {
  setDeleteBackgroundId(id);
  setShowDeletePopup(true);
};

  

const performDelete = async () => {
  try {
    const response = await axios.delete(`https://digitalback.we-demo.xyz/background/delete/${deleteBackgroundId}`);
    if (response.status === 200) {
      console.log('Background deleted successfully');
      fetchBackgrounds();
    } else {
      console.error('Delete request failed:', response.status);
    }
  } catch (error) {
    console.error('Error deleting background:', error);
  }
  setShowDeletePopup(false);
};




useEffect(() => {
  fetchBackgrounds();
  if (editingBackground && !hasFocused) {
    const updateNameInputField = document.getElementById('update-background-name');
    if (updateNameInputField) {
      updateNameInputField.focus();
      setHasFocused(true);
    }
  }

  // Reset focus state when editingBackground is set to null (e.g., after update or cancel)
  if (!editingBackground) {
    setHasFocused(false);
  }
}, [editingBackground]);
  

  return (
    <>
  <div className="content">
        <Row>
          <Col md="12">
            <Card style={{ backgroundColor: '#CFCFCF' }}>
              <CardHeader>
                <CardTitle tag="h4">Home Page</CardTitle>
              </CardHeader>
              <CardBody >
                {/* <Form>
                  <Row>
                   
                    <Col md="5">
                      <FormGroup>
                        <label>Name:</label>
                        <Input type="text" id="newBackgroundName" />
                      </FormGroup>
                    </Col>
                    
                    <Col md="5">
                      <FormGroup>
                        <label>Text:</label>
                        <Input type="text" id="newBackgroundText" />
                      </FormGroup>
                    </Col>
                   
                    <Col md="8">
                      <FormGroup>
                        <label>Image:</label>
                        <Input type="file" id="newBackgroundImage" />
                      </FormGroup>
                    </Col>
                   
                    <Col md="8">
                      <FormGroup>
                        <label>Icon:</label>
                        <Input type="file" id="newBackgroundIcon" />
                      </FormGroup>
                    </Col>
                    <Col md="8">
                      <Button color="primary" onClick={addBackground}>
                        Add Background
                      </Button>
                      {addSuccessMessage && (
   <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
      {addSuccessMessage}
    </div>
  )}
  {addMessageError && (
    <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
      {addMessageError}
    </div>
  )}
                    </Col>
                  </Row>
                </Form>
                {addError && (
        <div style={{ color: 'red', marginBottom: '10px' }}>
          {addError}
        </div>
          )} */}
                <CardFooter>
                  <div>Total backgrounds: {backgroundCount}</div>
                </CardFooter>
                <Table responsive >
                  <thead className="text-primary">
                    <tr>
                      <th>Name</th>
                      <th>Arabic Name</th>
                      <th>Text</th>
                      <th>Arabic Text</th>
                      <th>Image</th>
                      <th>Icon</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
  {backgrounds.map((background) => (
    <tr key={background.id}>
      <td>{background.name}</td>
      <td>{background.name_ar}</td>
      <td>{background.text}</td>
      <td>{background.text_ar}</td>
      <td>
        {background.image_path && (
          <img
            src={`https://digitalback.we-demo.xyz/uploads/${background.image_path}`}
            alt="Background"
            style={{ maxWidth: "100px" }}
          />
        )}
      </td>
      <td>
        {background.icon_path && (
          <img
            src={`https://digitalback.we-demo.xyz/uploads/${background.icon_path}`}
            alt="Icon"
            style={{ maxWidth: "100px" }}
          />
        )}
      </td>
      <td>
        <Button
          color="success"
          onClick={() => setEditingBackground(background)}
        >
          Update
        </Button>{" "}
        {/* <Button
          color="danger"
          onClick={() => deleteBackground(background.id)}
        >
          Delete
        </Button> */}
      </td>
    </tr>
  ))}
</tbody>

                </Table>
              </CardBody>
              {editingBackground && (
  <CardFooter>
    <Form>
      <Row>
        <Col md="5">
          <FormGroup>
            <label>Update Name:</label>
            <Input
              type="text"
              name="name"
              id="update-background-name"
              value={editingBackground.name}
              onChange={(e) =>
                setEditingBackground({
                  ...editingBackground,
                  name: e.target.value,
                })
              }
            />
          </FormGroup>
        </Col>
        <Col md="5">
          <FormGroup>
            <label>Update Arabic Name:</label>
            <Input
              type="text"
              name="name_ar"
              id="update-background-name"
              value={editingBackground.name_ar}
              onChange={(e) =>
                setEditingBackground({
                  ...editingBackground,
                  name_ar: e.target.value,
                })
              }
            />
          </FormGroup>
        </Col>
        <Col md="5">
          <FormGroup>
            <label>Update Text:</label>
            <Input
              type="text"
              name="text"
              value={editingBackground.text}
              onChange={(e) =>
                setEditingBackground({
                  ...editingBackground,
                  text: e.target.value,
                })
              }
            />
          </FormGroup>
        </Col>
        <Col md="5">
          <FormGroup>
            <label>Update Arabic Text:</label>
            <Input
              type="text"
              name="text_ar"
              value={editingBackground.text_ar}
              onChange={(e) =>
                setEditingBackground({
                  ...editingBackground,
                  text_ar: e.target.value,
                })
              }
            />
          </FormGroup>
        </Col>
        <Col md="8">
          <FormGroup>
            <label>Current Image:</label>
            {editingBackground.image_path && (
              <div>
                <img
                  src={`https://digitalback.we-demo.xyz/uploads/${editingBackground.image_path}`}
                  alt="Background Image"
                  style={{ maxWidth: '200px', marginBottom: '10px' }}
                />
              </div>
            )}
            <label>Update Image (optional):</label>
            <Input
              type="file"
              name="image"
              onChange={(e) => setNewImage(e.target.files[0])}
            />
          </FormGroup>
        </Col>
        <Col md="8">
          <FormGroup>
            <label>Current Icon:</label>
            {editingBackground.icon_path && (
              <div>
                <img
                  src={`https://digitalback.we-demo.xyz/uploads/${editingBackground.icon_path}`}
                  alt="Background Icon"
                  style={{ maxWidth: '100px', marginBottom: '10px' }}
                />
              </div>
            )}
            <label>Update Icon (optional):</label>
            <Input
              type="file"
              name="icon"
              onChange={(e) => setNewIcon(e.target.files[0])}
            />
          </FormGroup>
        </Col>
        <Col md="12">
          <Button
            className="btn-round"
            color="info"
            onClick={() => updateBackground(editingBackground.id)}
          >
            Update HomePage
          </Button>{" "}
          <Button
            className="btn-round"
            color="secondary"
            onClick={() => setEditingBackground(null)}
          >
            Cancel
          </Button> {" "} <br />
          {updateError && (
          <div style={{ color: 'red', marginTop: '10px' }}>
            {updateError}
          </div>
        )}
        {updateSuccessMessage && (
        <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
          {updateSuccessMessage}
        </div>
      )}
      {updateMessageError && (
    <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
      {updateMessageError}
    </div>
  )}
        </Col>
      </Row>
    </Form>
  </CardFooter>
)}

            </Card>
          </Col>
        </Row>
        <Modal isOpen={showDeletePopup} toggle={() => setShowDeletePopup(false)}>
  <ModalHeader toggle={() => setShowDeletePopup(false)}>Confirm Delete</ModalHeader>
  <ModalBody>
    Are you sure you want to delete this background?
  </ModalBody>
  <ModalFooter>
    <Button color="danger" onClick={() => performDelete()}>Delete</Button>{' '}
    <Button color="secondary" onClick={() => setShowDeletePopup(false)}>Cancel</Button>
  </ModalFooter>
</Modal>
      </div>
    </>
  );
}

export default HomePage;
