import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "../assets/css/style.css";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  Input,
  Button,
} from "reactstrap";
import ReactQuill, { Quill } from 'react-quill';

function PrivacyPolicies() {
  const [policies, setPolicies] = useState([]);
  const [newPolicy, setNewPolicy] = useState({
    title: "",
    title_ar: "",
    content: "",
    content_ar: "",
  });
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [selectedPolicyId, setSelectedPolicyId] = useState(null);
  const [updatePolicy, setUpdatePolicy] = useState({
    title: "",
    title_ar: "",
    content: "",
    content_ar: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [updateErrorMessage, setUpdateErrorMessage] = useState("");
  const [policies1Count, setpolicies1Count] = useState(0);
  const [editorHtml, setEditorHtml] = useState("");
  const quillRef = useRef(null);
  const quillRefAr = useRef(null);

 

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPolicy({ ...newPolicy, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://digitalback.we-demo.xyz/privacy/add",
        newPolicy
      );

      console.log("Privacy policy added successfully:", response.data);
      fetchData();
      setNewPolicy({
        title: "",
        title_ar: "",
        content: "",
        content_ar: "",
      });
    } catch (error) {
      console.error("Error adding privacy policy:", error);
    }
  };

  const fetchData = async () => {
  try {
    const response = await axios.get("https://digitalback.we-demo.xyz/privacy/list");
    if (response.data && response.data.success) {
      // Assuming you want to store it as an array
      setPolicies(response.data.data);
      setpolicies1Count(1);
    } else {
      console.error("Unexpected response format:", response.data);
      // Handle this situation appropriately
    }
  } catch (error) {
    console.error(`Error getting policies: ${error}`);
    setError('Error fetching policies. Please try again later.');
  } finally {
    setLoading(false);
  }
};

  
  
  const handleDelete = async (policyId) => {
    console.log("Deleting policy with ID:", policyId);

    try {
      await axios.delete(`https://digitalback.we-demo.xyz/privacy/delete/${policyId}`);
      setPolicies((prevPolicies) =>
        prevPolicies.filter((policy) => policy.id !== policyId)
      );
    } catch (error) {
      console.error("Error deleting policy:", error);
    }
  };

  const handleUpdate = (policy) => {
    setSelectedPolicyId(policy.id);
    setUpdatePolicy({
      title: policy.title,
      title_ar: policy.title_ar,
      content: policy.content,
      content_ar: policy.content_ar,
    });
    setShowUpdateForm(true); // This will trigger the useEffect hook
  };




  const handlePolicyUpdate = async (e) => {
    e.preventDefault();
  
    // Validation: Check if title and content are filled
    if (!updatePolicy.title.trim() || !updatePolicy.title_ar.trim() || !updatePolicy.content.trim() || !updatePolicy.content_ar.trim()) {
      setUpdateErrorMessage("Title and Content are required.");
      setTimeout(() => {
        setUpdateErrorMessage("");
      }, 5000);
  
      return;
    }
  
    try {
      const response = await axios.put(
        `https://digitalback.we-demo.xyz/privacy/update/${selectedPolicyId}`,
        updatePolicy
      );
  
      if (response.status === 200) {
        console.log("Privacy policy updated successfully:", response.data);
        fetchData();
        setUpdatePolicy({ title: "", title_ar: "", content: "", content_ar: "" });
        setSelectedPolicyId(null);
        setSuccessMessage("Privacy Policy updated successfully");
        setUpdateErrorMessage(""); // Clear the error message
        setTimeout(() => {
          setSuccessMessage("");
        }, 5000);
      }
    } catch (error) {
      console.error("Error updating privacy policy:", error);
      setUpdateErrorMessage("Failed to update privacy policy. Please try again.");
    }
  };

  const insertImage = (url) => {
    const editor = quillRef.current.getEditor();
    const range = editor.getSelection(true);
    editor.insertEmbed(range.index, 'image', url);
  };

  const selectLocalImage = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
  
    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append('image', file);
  
      try {
        const response = await axios.post('https://digitalback.we-demo.xyz/privacy/upload', formData);
        const imageUrl = response.data.imageUrl; // URL from server
        insertImage(imageUrl); // Insert the image URL into the editor
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    };
  };
  
  

  const modules = {
    toolbar: {
      container: [
        // Add other toolbar options as you need
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1'}, { 'outdent': '1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction
        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],
        ['clean'],                                        // remove formatting button
        ['link', 'image', 'video'],                       // link and image, video
      ],
      handlers: {
        'image': selectLocalImage // Handle image uploads
      }
    }
  };
  
  
  useEffect(() => {
    fetchData();
    if (showUpdateForm) {
      // Focus on the input field when the form is shown
      const inputField = document.getElementById('update-policy-title');
      if (inputField) {
        inputField.focus();
      }
    }
  }, [showUpdateForm]);

  return (
    <>
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
      <div >
        {/* <Form onSubmit={handleSubmit}>
          <Row>
            <Col md="6">
              <FormGroup>
                <label>Title:</label>
                <Input
                  type="text"
                  name="title"
                  value={newPolicy.title}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
            <Col md="7">
              <FormGroup>
                <label htmlFor="content" className="textarea-label">Content:</label>
                <textarea
                  className="form-control" // Bootstrap class for styling textareas
                  id="content"
                  name="content"
                  value={newPolicy.content}
                  onChange={handleInputChange}
                  style={{ minHeight: '300px' }} // Set a minimum height
                />
              </FormGroup>
            </Col>

          </Row>
          <Row>
            <Col md="12">
              <div className="update ml-auto mr-auto">
                <Button className="btn-round" color="primary" type="submit">
                  Add Policy
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
*/}
             <CardFooter>
          <div>Total Policies: {policies1Count}</div>
        </CardFooter> 
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Privacy Policy List</CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Title</th>
                      <th>Arabic Title</th>
                      <th>Content</th>
                      <th>Arabic Content</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {policies.map((policy) => (
                      <tr key={policy.id}>
                        <td>{policy.title}</td>
                        <td>{policy.title_ar}</td>
                        <td dangerouslySetInnerHTML={{ __html: policy.content }}></td>
                        <td dangerouslySetInnerHTML={{ __html: policy.content_ar }}></td>
                        <td>
                          {/* <Button 
                          color="danger"
                          onClick={() => handleDelete(policy.id)}>
                            Delete
                          </Button>{" "} */}
                          <Button 
                           color="success"
                          onClick={() => handleUpdate(policy)}>
                            Update
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      {showUpdateForm && (
        <div className="content mt-5"  
>
          <Form onSubmit={handlePolicyUpdate}>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label>Title:</label>
                  <Input
                type="text"
                name="title"
                id="update-policy-title" // Assign an ID here
                value={updatePolicy.title}
                onChange={(e) => setUpdatePolicy({ ...updatePolicy, title: e.target.value })}
              />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label>Arabic Title:</label>
                  <Input
                type="text"
                name="title_ar"
                id="update-policy-title_ar" // Assign an ID here
                value={updatePolicy.title_ar}
                onChange={(e) => setUpdatePolicy({ ...updatePolicy, title_ar: e.target.value })}
              />
                </FormGroup>
              </Col>
              <Col md="7">
                        <FormGroup>
                          <label htmlFor="updateContent" className="textarea-label">
                            Update content:
                          </label>
                          <div
                            style={{
                              height: '300px', // Adjust the height as needed
                              overflowY: 'auto', // Add a vertical scrollbar when content overflows
                            }}
                          >
                            <ReactQuill
                              ref={quillRef}
                              value={updatePolicy ? updatePolicy.content : ''}
                              onChange={(html) => 
                                setUpdatePolicy({
                                  ...updatePolicy,
                                  content: html
                                })
                              }
                              modules={{
                                toolbar: [
                                  [{ header: "1" }, { header: "2" }, { font: [] }],
                                  [{ list: "ordered" }, { list: "bullet" }],
                                  ["bold", "italic", "underline"],
                                  ["image"],
                                ],
                              }}
                              style={{ height: '80%' }} // Set the height of ReactQuill to 80%
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="7">
  <FormGroup>
    <label htmlFor="updateArabicContent" className="textarea-label">
      Update Arabic content:
    </label>
    <div
      style={{
        height: '300px', // Adjust the height as needed
        overflowY: 'auto', // Add a vertical scrollbar when content overflows
      }}
    >
<ReactQuill
  ref={quillRefAr} // Assuming you have a separate ref for the Arabic editor
  value={updatePolicy ? updatePolicy.content_ar : ''}
  onChange={(htmlAr) => {
    if (htmlAr !== updatePolicy.content_ar) {
      setUpdatePolicy({
        ...updatePolicy,
        content_ar: htmlAr
      });
    }
  }}
  modules={{
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline"],
      ["image"],
      // Add any other toolbar options you need
    ],
  }}
  style={{ height: '80%' }} // Set the height of ReactQuill to 80%
/>



    </div>
  </FormGroup>
</Col>
            </Row>
            <Row>
              <Col md="12">
                <div className="update ml-auto mr-auto">
                  <Button className="btn-round" color="primary" type="submit">
                    Update Policy
                  </Button>{" "}
            <Button
              className="btn-round"
              color="secondary"
              onClick={() => {
                setShowUpdateForm(false);
                setUpdatePolicy({
                  title: "",
                  title_ar: "",
                  content: "",
                  content_ar: "",
                });
              }}
            >
              Cancel
            </Button>
            {successMessage && (
        <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
          {successMessage}
        </div>
      )}
      {updateErrorMessage && (
        <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
          {updateErrorMessage}
        </div>
      )}
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      )}
   </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default PrivacyPolicies;
