// Terms.js (Front-End)

import React, { useEffect, useState, useRef  } from "react";
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  Input,
  Button,
} from "reactstrap";
import ReactQuill, { Quill } from 'react-quill';
function Terms() {
  const [terms, setTerms] = useState([]);
  const [newTerm, setNewTerm] = useState({
    title: "",
    title_ar: "",
    content: "",
    content_ar: "",
  });
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [selectedTermId, setSelectedTermId] = useState(null);
  const [updateTerm, setUpdateTerm] = useState({
    title: "",
    title_ar: "",
    content: "",
    content_ar: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [termCount, setTermCount] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [updateErrorMessage, setUpdateErrorMessage] = useState("");
  const [editorHtml, setEditorHtml] = useState("");
  const quillRef = useRef(null);
  const quillRefAr = useRef(null);



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTerm({ ...newTerm, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("https://digitalback.we-demo.xyz/terms/add", newTerm);

     
      fetchData();
      
      setTerms([response.data, ...terms]);

      setNewTerm({
        title: "",
        title_ar: "",
        content: "",
        content_ar: "",
      });
    } catch (error) {
      console.error("Error adding term:", error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get("https://digitalback.we-demo.xyz/terms/list");
      if (response.data && response.data.success) {
        setTerms(response.data.data); // Wrapping the object in an array
        setTermCount(1); // Since it'sa single object
      } else {
        console.error("Unexpected response format:", response.data);
        // Handle this situation appropriately
      }
    } catch (error) {
      console.error(`Error getting data: ${error}`);
      setLoading(false);
      setError("Error fetching data. Please try again later.");
    }
  };
  
  

  const handleDelete = async (termId) => {
    console.log("Deleting term with ID:", termId);

    try {
      await axios.delete(`https://digitalback.we-demo.xyz/terms/delete/${termId}`);
      setTerms((prevTerms) => prevTerms.filter((term) => term.id !== termId));
    } catch (error) {
      console.error("Error deleting term:", error);
    }
  };

  const handleUpdate = (term) => {
    setSelectedTermId(term.id);
    setUpdateTerm({
      title: term.title,
      title_ar: term.title_ar,
      content: term.content,
      content_ar: term.content_ar,
    });
    setShowUpdateForm(!showUpdateForm);
    };

    const handleTermUpdate = async (e) => {
      e.preventDefault();
    
      // Validation: Check if title and content are filled
      if (!updateTerm.title.trim() || !updateTerm.title_ar.trim() || !updateTerm.content.trim() || !updateTerm.content_ar.trim()) {
        setUpdateErrorMessage("Title and Content are required.");
        setTimeout(() => {
          setUpdateErrorMessage("");
        }, 5000);
    
        return;
      }
    
      try {
        const response = await axios.put(
          `https://digitalback.we-demo.xyz/terms/update/${selectedTermId}`,
          updateTerm
        );
    
        if (response.status === 200) {
          console.log("Term updated successfully:", response.data);
          fetchData();
          setUpdateTerm({ title: "", title_ar: "", content: "", content_ar: "" });
          setSelectedTermId(null);
          setSuccessMessage("Term updated successfully");
          setUpdateErrorMessage(""); // Clear any existing error message
          setTimeout(() => {
            setSuccessMessage("");
          }, 5000);
        }
      } catch (error) {
        console.error("Error updating term:", error);
        setUpdateErrorMessage("Failed to update term. Please try again.");
      }
    };
    
    const insertImage = (url) => {
      const editor = quillRef.current.getEditor();
      const range = editor.getSelection(true);
      editor.insertEmbed(range.index, 'image', url);
    };
  
    const selectLocalImage = () => {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.click();
    
      input.onchange = async () => {
        const file = input.files[0];
        const formData = new FormData();
        formData.append('image', file);
    
        try {
          const response = await axios.post('https://digitalback.we-demo.xyz/privacy/upload', formData);
          const imageUrl = response.data.imageUrl; // URL from server
          insertImage(imageUrl); // Insert the image URL into the editor
        } catch (error) {
          console.error('Error uploading image:', error);
        }
      };
    };
    
    
  
    const modules = {
      toolbar: {
        container: [
          // Add other toolbar options as you need
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          [{ 'header': 1 }, { 'header': 2 }],               // custom button values
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
          [{ 'indent': '-1'}, { 'outdent': '1' }],          // outdent/indent
          [{ 'direction': 'rtl' }],                         // text direction
          [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
          [{ 'font': [] }],
          [{ 'align': [] }],
          ['clean'],                                        // remove formatting button
          ['link', 'image', 'video'],                       // link and image, video
        ],
        handlers: {
          'image': selectLocalImage // Handle image uploads
        }
      }
    };
    

  useEffect(() => {
    fetchData();
    if (showUpdateForm) {
      // Assuming the first input field has an id 'update-title'
      document.getElementById('update-title').focus();
    }
  }, [showUpdateForm]);
  return (
    <>
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
  <div >
            {/* <Form onSubmit={handleSubmit}>
          <Row>
            <Col md="6">
              <FormGroup>
                <label>Title:</label>
                <Input
                  type="text"
                  name="title"
                  value={newTerm.title}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
          <Col md="7">
              <FormGroup>
                <label htmlFor="content" className="textarea-label">Content:</label>
                <textarea
                  className="form-control" // Bootstrap class for styling textareas
                  id="content"
                  name="content"
                  value={newTerm.content}
                  onChange={handleInputChange}
                  style={{ minHeight: '300px' }} // Set a minimum height
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="update ml-auto mr-auto">
                <Button className="btn-round" color="primary" type="submit">
                  Add Term
                </Button>
              </div>
            </Col>
          </Row>
        </Form> */}
        {/* <div className="content"> */}
          <CardFooter>
            <div>Total Terms: {termCount}</div>
          </CardFooter>
        {/* </div> */}
        
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Terms</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Title</th>
                        <th>Arabic Title</th>
                        <th>Content</th>
                        <th>Arabic Content</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {terms.map((term) => (
                        <tr key={term.id}>
                          <td>{term.title}</td>
                          <td>{term.title_ar}</td>
                          <td dangerouslySetInnerHTML={{ __html: term.content }}></td>
                          <td dangerouslySetInnerHTML={{ __html: term.content_ar }}></td>
                          <td>
                            {/* <Button 
                            color="danger"
                            onClick={() => handleDelete(term.id)}>
                              Delete
                            </Button>{" "} */}
                            <Button 
                             color="success"
                            onClick={() => handleUpdate(term)}>
                              Update
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      

      {showUpdateForm  && (
    <div className="content mt-5">
                <Form onSubmit={handleTermUpdate}>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label>Title:</label>
                  <Input
                    type="text"
                    name="title"
                    id="update-title"
                    value={updateTerm.title}
                    onChange={(e) =>
                      setUpdateTerm({ ...updateTerm, title: e.target.value })
                    }
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label>Arabic Title:</label>
                  <Input
                    type="text"
                    name="title_ar"
                    id="update-title_ar"
                    value={updateTerm.title_ar}
                    onChange={(e) =>
                      setUpdateTerm({ ...updateTerm, title_ar: e.target.value })
                    }
                  />
                </FormGroup>
              </Col>
              <Col md="7">
                        <FormGroup>
                          <label htmlFor="updateContent" className="textarea-label">
                            Update content:
                          </label>
                          <div
                            style={{
                              height: '300px', // Adjust the height as needed
                              overflowY: 'auto', // Add a vertical scrollbar when content overflows
                            }}
                          >
                            <ReactQuill
                              ref={quillRef}
                              value={updateTerm ? updateTerm.content : ''}
                              onChange={(html) => 
                                setUpdateTerm({
                                  ...updateTerm,
                                  content: html
                                })
                              }
                              modules={{
                                toolbar: [
                                  [{ header: "1" }, { header: "2" }, { font: [] }],
                                  [{ list: "ordered" }, { list: "bullet" }],
                                  ["bold", "italic", "underline"],
                                  ["image"],
                                ],
                              }}
                              style={{ height: '80%' }} // Set the height of ReactQuill to 80%
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="7">
  <FormGroup>
    <label htmlFor="updateArabicContent" className="textarea-label">
      Update Arabic content:
    </label>
    <div
      style={{
        height: '300px', // Adjust the height as needed
        overflowY: 'auto', // Add a vertical scrollbar when content overflows
      }}
    >
<ReactQuill
  ref={quillRefAr} // Assuming you have a separate ref for the Arabic editor
  value={updateTerm ? updateTerm.content_ar : ''}
  onChange={(htmlAr) => {
    if (htmlAr !== updateTerm.content_ar) {
      setUpdateTerm({
        ...updateTerm,
        content_ar: htmlAr
      });
    }
  }}
  modules={{
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline"],
      ["image"],
      // Add any other toolbar options you need
    ],
  }}
  style={{ height: '80%' }} // Set the height of ReactQuill to 80%
/>



    </div>
  </FormGroup>
</Col>
            </Row>
            <Row>
              <Col md="12">
                <div className="update ml-auto mr-auto">
                  <Button className="btn-round" color="primary" type="submit">
                    Update Term
                  </Button>{" "}
      <Button
        className="btn-round"
        color="secondary"
        onClick={() => {
          setShowUpdateForm(false);
          setSelectedTermId(null);
          setUpdateTerm({
            title: "",
            title_ar: "",
            content: "",
            content_ar: "",
          });
        }}
      >
        Cancel
      </Button>
      {successMessage && (
        <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
          {successMessage}
        </div>
      )}

{updateErrorMessage && (
        <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
          {updateErrorMessage}
        </div>
      )}

                </div>
              </Col>
            </Row>
          </Form>
        </div>
      )}
     </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Terms;
