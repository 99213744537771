import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  Input,
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

function TankShop() {
  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState({
    title: "",
    title_ar: "",
    price: "",
    content: "",
    content_ar: "",
   
  });
  const [itemImage, setItemImage] = useState(null);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [updateItem, setUpdateItem] = useState({
    title: "",
    title_ar: "",
    price: "",
    content: "",
    content_ar: "",
    image_path: "", // Assuming you want to show the current image
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [itemCount, setItemCount] = useState(0); 
  const [addError, setAddError] = useState("");
  const [addImageError, setAddImageError] = useState("");
  const [updateError, setUpdateError] = useState("");
  const [addSuccessMessage, setAddSuccessMessage] = useState("");
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState("");
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  


  const isValidImageExtension = (fileName) => {
    const validExtensions = ['.webp', '.gif', '.png', '.jpg', '.jpeg'];
    const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase();
  
    return validExtensions.includes(`.${fileExtension}`);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewItem({ ...newItem, [name]: value });
  };

  const handleImageChange = (e) => {
    const image = e.target.files[0];
    setItemImage(image);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validate the input fields
    if (!newItem.title.trim() || !newItem.title_ar.trim() || !newItem.price.trim() || !newItem.content.trim() || !newItem.content_ar.trim() || !itemImage) {
      setAddError("All fields are required.");
      setTimeout(() => {
        setAddError("");
      }, 5000);
      return;
    }
  
    if (itemImage && !isValidImageExtension(itemImage.name)) {
      setAddImageError("Invalid file extension. Please upload .WEBP, .GIF, .PNG, .JPG, or .JPEG");
      setTimeout(() => {
        setAddImageError("");
      }, 5000);
      return;
    }
    setAddImageError("");
  
    try {
      const formData = new FormData();
      formData.append("image", itemImage);
      formData.append("title", newItem.title);
      formData.append("title_ar", newItem.title_ar);
      formData.append("price", newItem.price);
      formData.append("content", newItem.content);
      formData.append("content_ar", newItem.content_ar);
  
      const response = await axios.post(
        "https://digitalback.we-demo.xyz/shop/add",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      fetchData();
      setNewItem({ title: "", title_ar: "", price: "", content: "", content_ar: "" });
      setItemImage(null);
      setAddSuccessMessage("Tank Shop added successfully");
      setTimeout(() => {
        setAddSuccessMessage("");
      }, 5000);
    } catch (error) {
      console.error("Error adding item:", error);
      setAddError("Error occurred while adding the item.");
    }
  };
  

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://digitalback.we-demo.xyz/shop/list"
      );
      setItems(response.data.data);
      setItemCount(response.data.data.length); // Update item count
      setLoading(false); // Set loading to false after data is fetched
      setError(null); // Clear any previous errors
    } catch (error) {
      console.error(`Error getting data: ${error}`);
      setLoading(false); // Set loading to false in case of error
      setError("Error fetching data. Please try again later."); // Set the error message
    }
  };

  const handleDelete = (itemId) => {
    setDeleteItemId(itemId);
    setShowDeletePopup(true);
  };


  const performDelete = async () => {
    try {
      await axios.delete(`https://digitalback.we-demo.xyz/shop/delete/${deleteItemId}`);
      setItems(items.filter((item) => item.id !== deleteItemId));
      setShowDeletePopup(false);
    } catch (error) {
      console.error("Error deleting item:", error);
      // Optionally handle the error in UI
    }
  };
  
  

  

  const handleUpdate = (item) => {
    setSelectedItemId(item.id); // Set the selected item ID
    setUpdateItem({
      title: item.title,
      title_ar: item.title_ar,
      price: item.price,
      content: item.content,
      content_ar: item.content_ar,
      image_path: item.image_path, // Set the current image path
    });
    setShowUpdateForm(true); // Display the update form
  };

const handleItemUpdate = async (e) => {
  e.preventDefault();

  if (!updateItem.title.trim() || !updateItem.title_ar.trim() || !updateItem.price.trim() || !updateItem.content.trim() || !updateItem.content_ar.trim()) {
    setUpdateError("Title, Price, and Content are required.");
    setTimeout(() => setUpdateError(""), 5000);
    return;
  }

  const formData = new FormData();
  formData.append("title", updateItem.title);
  formData.append("title_ar", updateItem.title_ar);
  formData.append("price", updateItem.price);
  formData.append("content", updateItem.content);
  formData.append("content_ar", updateItem.content_ar);

  // Append image only if a new one is selected
  if (itemImage) {
    if (!isValidImageExtension(itemImage.name)) {
      setUpdateError("Invalid file extension. Allowed: .WEBP, .GIF, .PNG, .JPG, .JPEG");
      setTimeout(() => setUpdateError(""), 5000);
      return;
    }
    formData.append("image", itemImage);
  } else {
    formData.append("currentImage", updateItem.image_path); // Include the current image path
  }
  try {
    const response = await axios.put(
      `https://digitalback.we-demo.xyz/shop/update/${selectedItemId}`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );

        console.log("Item updated successfully:", response.data);
        fetchData();
        setUpdateSuccessMessage("Tank Shop updated successfully");
        setTimeout(() => {
            setUpdateSuccessMessage("");
        }, 5000);

        resetUpdateForm();
      } catch (error) {
        console.error("Error updating item:", error);
        setUpdateError("Error occurred while updating the item.");
      }
    };

const resetUpdateForm = () => {
    setUpdateItem({
        title: "",
        title_ar: "",
        price: "",
        content: "",
        content_ar: "",
        image_path: "",
    });
    setItemImage(null);
    setSelectedItemId(null);
    // setShowUpdateForm(false);
};


  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Focus on the title input field when the update form is shown
    if (showUpdateForm) {
      const updateTitleInputField = document.getElementById('update-item-title');
      if (updateTitleInputField) {
        updateTitleInputField.focus();
      }
    }
  }, [showUpdateForm]);

  return (
    <>
      <div className="content">
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md="6">
              <FormGroup>
                <label>Title:</label>
                <Input
                  type="text"
                  name="title"
                  value={newItem.title}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label>Arabic Title:</label>
                <Input
                  type="text"
                  name="title_ar"
                  value={newItem.title_ar}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label>Price:</label>
                <Input
                  type="number"
                  name="price"
                  value={newItem.price}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="7">
              <FormGroup>
              <label for="content" className="textarea-label">Content:</label>
               <textarea
                 className="form-control" // Bootstrap class for styling textareas
                 id="content"
                 name="content"
                  value={newItem.content}
                  onChange={handleInputChange}
                  style={{ minHeight: '300px' }}
                />
              </FormGroup>
            </Col>
            <Col md="7">
              <FormGroup>
              <label for="content" className="textarea-label">Arabic Content:</label>
               <textarea
                 className="form-control" // Bootstrap class for styling textareas
                 id="content_ar"
                 name="content_ar"
                  value={newItem.content_ar}
                  onChange={handleInputChange}
                  style={{ minHeight: '300px' }}
                />
              </FormGroup>
            </Col>
            
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <label>Image:</label>
                <Input
                  type="file"
                  name="image"
                  onChange={handleImageChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="update ml-auto mr-auto">
                <Button
                  className="btn-round"
                  color="primary"
                  type="submit"
                >
                  Add Item
                </Button>
                {addSuccessMessage && (
    <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
      {addSuccessMessage}
    </div>
  )}
 {addError && (
    <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
      {addError}
    </div>
  )}
   {addImageError && (
      <div style={{ color: 'red' }}>

      {addImageError}
    </div>
  )}

              </div>
            </Col>
          </Row>
        </Form>
     
        <div className="content">
          <CardFooter>
            <div>Total Items: {itemCount}</div>
          </CardFooter>
        </div>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Shop Items List</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Title</th>
                        <th>Arabic Title</th>
                        <th>Price</th>
                        <th>Content</th>
                        <th>Arabic Content</th>
                        <th>Image</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((item) => (
                        <tr key={item.id}>
                          <td>{item.title}</td>
                          <td>{item.title_ar}</td>
                          <td>{item.price}</td>
                          <td>{item.content}</td>
                          <td>{item.content_ar}</td>
                          <td>
        {item.image_path && (
          <img src={`https://digitalback.we-demo.xyz/${item.image_path}`} alt={item.title} style={{ maxWidth: '100px' }} />
        )}
      </td>

      <td>
                            <Button 
                             color="success"
                            onClick={() => handleUpdate(item)}>
                              Update
                            </Button>
                          </td>
                          <td>
                            <Button
                            color="danger"
                              onClick={() =>
                                handleDelete(item.id, item.image_path)
                              }
                            >
                              Delete
                            </Button>{" "}
                            </td>

                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      {/* Display the update form when showUpdateForm is true */}
      {showUpdateForm && (
        <div className="content">
          <Form onSubmit={handleItemUpdate}>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label>Title:</label>
                  <Input
                    type="text"
                    name="title"
                    
                    value={updateItem.title}
                    onChange={(e) =>
                      setUpdateItem({
                        ...updateItem,
                        title: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label>Arabic Title:</label>
                  <Input
                    type="text"
                    name="title_ar"
                    
                    value={updateItem.title_ar}
                    onChange={(e) =>
                      setUpdateItem({
                        ...updateItem,
                        title_ar: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label>Price:</label>
                  <Input
                    type="number"
                    name="price"
                    value={updateItem.price}
                    onChange={(e) =>
                      setUpdateItem({
                        ...updateItem,
                        price: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup>
                <label for="content" className="textarea-label">Content:</label>
               <textarea
                 style={{ minHeight: '300px', width: '590px'  }}
                    type="text"
                    name="content"
                    value={updateItem.content}
                    
                    onChange={(e) =>
                      setUpdateItem({
                        ...updateItem,
                        content: e.target.value,
                        
                      })
                    }
                    
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                <label for="content" className="textarea-label">Arabic Content:</label>
               <textarea
                 style={{ minHeight: '300px', width: '590px'  }}
                    type="text"
                    name="content_ar"
                    value={updateItem.content_ar}
                    
                    onChange={(e) =>
                      setUpdateItem({
                        ...updateItem,
                        content_ar: e.target.value,
                        
                      })
                    }
                    
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
              <FormGroup>
    <label>Current Image:</label>
    {updateItem.image_path && (
      <div>
        <img
          src={`https://digitalback.we-demo.xyz/uploads/${updateItem.image_path}`}
          alt="Item Image"
          style={{ maxWidth: '200px', marginBottom: '10px' }}
        />
      </div>
    )}
            <label>Update Image (optional):</label>
            <Input
              type="file"
              name="image"
              id= "update-item-title"
              onChange={handleImageChange}
            />
          </FormGroup>
         
        </Col>
            </Row>
            <Row>
              <Col md="12">
                <div className="update ml-auto mr-auto">
                  <Button
                    className="btn-round"
                    color="primary"
                    type="submit"
                  >
                    Update Item
                  </Button>{" "}
      <Button
        className="btn-round"
        color="secondary"
        onClick={() => {
          setShowUpdateForm(false);
          setSelectedItemId(null);
          setUpdateItem({
            title: "",
            price: "",
            content: "",
            image_path: "", // Assuming you want to show the current image
          });
        }}
      >
        Cancel
      </Button>
    
      {updateSuccessMessage && (
      <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
        {updateSuccessMessage}
      </div>
    )}
    {addImageError && (
      <div style={{ color: 'red' }}>

      {addImageError}
    </div>
  )}
   {addError && (
    <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
      {addError}
    </div>
  )}
   {updateError && (
    <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
      {updateError}
    </div>
  )}
 
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      )}

<Modal isOpen={showDeletePopup} toggle={() => setShowDeletePopup(false)}>
  <ModalHeader toggle={() => setShowDeletePopup(false)}>Confirm Delete</ModalHeader>
  <ModalBody>Are you sure you want to delete this item?</ModalBody>
  <ModalFooter>
    <Button color="danger" onClick={performDelete}>Delete</Button>
    <Button color="secondary" onClick={() => setShowDeletePopup(false)}>Cancel</Button>
  </ModalFooter>
</Modal>


    </>
  );
}

export default TankShop;
