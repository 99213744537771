import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../assets/css/style.css';

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  Input,
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

function NotificationsComponent() {
  const [notifications, setNotifications] = useState([]);
  const [newNotification, setNewNotification] = useState({
    title: '',
    title_ar: '',
    date: '', 
    time: '', 
    content: '',
    content_ar: '',
  });
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [selectedNotificationId, setSelectedNotificationId] = useState(null);
  const [updateNotification, setUpdateNotification] = useState({
    title: '',
    title_ar: '',
    date: '',
    time: '', 
    content: '',
    content_ar: '',
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [notificationCount, setnotificationCount] = useState(0);
  const [addSuccessMessage, setAddSuccessMessage] = useState("");
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState("");
  const [addErrorMessage, setAddErrorMessage] = useState("");
  const [updateErrorMessage, setUpdateErrorMessage] = useState("");
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteNotificationId, setDeleteNotificationId] = useState(null);
  



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewNotification({ ...newNotification, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Check if all fields are filled
    if (!newNotification.title.trim() || !newNotification.title_ar.trim() || !newNotification.date.trim() || !newNotification.content.trim() || !newNotification.content_ar.trim()) {
      setAddErrorMessage("Title, Date, and Content are required to add a notification.");
      setTimeout(() => {
        setAddErrorMessage("");
      }, 5000);
      return;
    }
    
  
    try {
      const response = await axios.post(
        'https://digitalback.we-demo.xyz/notification/add',
        newNotification,
      );
      if (response.status === 200 || response.status === 201) {
        console.log('Notification added successfully:', response.data);
        fetchData();
        setNewNotification({
          title: '',
          title_ar: '',
          date: '',
          time: '',
          content: '',
          content_ar: '',
        });
        setAddSuccessMessage("Notification sent successfully");
        setTimeout(() => {
          setAddSuccessMessage("");
        }, 5000);
      }
    } catch (error) {
      console.error('Error adding notification:', error);
      setAddErrorMessage("Failed to add notification. Please try again.");
      // Set a timer to clear the error message after 5 seconds
      setTimeout(() => {
        setAddErrorMessage("");
      }, 5000);
    }
  };
  
  
  

  const fetchData = async () => {
    try {
      const response = await axios.get('https://digitalback.we-demo.xyz/notification/list2');
      setNotifications(response.data.data);
      setnotificationCount(response.data.data.length);
      setLoading(false);
      setError(null);
    } catch (error) {
      console.error(`Error getting notifications: ${error}`);
      setLoading(false);
      setError('Error fetching notifications. Please try again later.');
    }
  };

 // In NotificationsComponent.js
 const handleDelete = (id) => {
  setDeleteNotificationId(id);
  setShowDeletePopup(true);
};

const performDelete = async () => {
  try {
    const response = await axios.delete(`https://digitalback.we-demo.xyz/notification/delete/${deleteNotificationId}`);
    if (response.status === 200) {
      const updatedNotifications = notifications.filter(notification => notification.id !== deleteNotificationId);
      setNotifications(updatedNotifications);
      setShowDeletePopup(false);
    } else {
      console.error('Failed to delete notification');
    }
  } catch (error) {
    console.error('Error deleting notification:', error);
  }
};



// Replace fetchBackgrounds with fetchNotifications in useEffect and elsewhere as needed

  
const handleUpdate = (notification) => {
  setSelectedNotificationId(notification.id);
  setUpdateNotification({
    title: notification.title,
    title_ar: notification.title_ar,
    date: notification.date,
    time: notification.time || '',  
    content: notification.content,
    content_ar: notification.content_ar,
  });
  setShowUpdateForm(true);
};

const handleNotificationUpdate = async (e) => {
  e.preventDefault();

  // Check if all fields are filled
  if (!updateNotification.title.trim() || !updateNotification.title_ar.trim() || !updateNotification.date.trim() || !updateNotification.time.trim() || !updateNotification.content.trim() || !updateNotification.content_ar.trim()) {
    setUpdateErrorMessage("All fields are required to update the notification.");
    // Set a timer to clear the error message after 5 seconds
    setTimeout(() => {
      setUpdateErrorMessage("");
    }, 5000);
    return;
  }

  try {
    const response = await axios.put(
      `https://digitalback.we-demo.xyz/notification/update/${selectedNotificationId}`,
      updateNotification,
    );
    if (response.status === 200) {
      console.log('Notification updated successfully:', response.data);
      fetchData();
      setUpdateNotification({
        title: '',
        title_ar: '',
        date: '',
        time: '',
        content: '',
        content_ar: '',
      });
      setSelectedNotificationId(null);
      setUpdateSuccessMessage("Notification updated successfully");
      setTimeout(() => {
        setUpdateSuccessMessage("");
      }, 5000);
    }
  } catch (error) {
    console.error('Error updating notification:', error);
    setUpdateErrorMessage("Failed to update notification. Please try again.");
    // Set a timer to clear the error message after 5 seconds
    setTimeout(() => {
      setUpdateErrorMessage("");
    }, 5000);
  }
};

  

 

  const formatTime12Hour = (time24) => {
    if (!time24) {
      return 'No time set'; // Return a default message or an empty string
    }
    const [hours, minutes] = time24.split(':');
    const hoursInt = parseInt(hours, 10);
    const suffix = hoursInt >= 12 ? 'PM' : 'AM';
    const hours12 = ((hoursInt + 11) % 12) + 1;
    return `${('0' + hours12).slice(-2)}:${minutes} ${suffix}`;
  };
  
  
  const formatDateToReadableString = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };
  useEffect(() => {
    fetchData();
    if (showUpdateForm) {
      // Focus on the input field when the form is shown
      const inputField = document.getElementById('update-notification-title');
      if (inputField) {
        inputField.focus();
      }
    }
  }, [showUpdateForm]);
  
  useEffect(() => {
    console.log("Notifications:", notifications); // Debug log to check the structure
  }, [notifications]);
  

  return (
    <>
      <div className="content">
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md="7">
              <FormGroup>
                <label>Title:</label>
                <Input
                  type="text"
                  name="title"
                  value={newNotification.title}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
            <Col md="7">
              <FormGroup>
                <label>Arabic Title:</label>
                <Input
                  type="text"
                  name="title_ar"
                  value={newNotification.title_ar}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
            <Col md="7">
              <FormGroup>
                <label>Date:</label>
                <Input
                  type="date"
                  name="date"
                  value={newNotification.date}
                  onChange={handleInputChange}
                />
              </FormGroup>
              </Col>
           <Col md="7">
              <FormGroup>
                <label>Time:</label>
                <Input
  type="time"
  name="time"
  value={updateNotification.time || ''} // Use the state value or an empty string
  onChange={(e) =>
    setUpdateNotification({
      ...updateNotification,
      time: e.target.value,
    })
  }
/>
              </FormGroup>
            </Col>

            
            <Col md="7">
              <FormGroup>
                <label htmlFor="content" className="textarea-label">Content:</label>
                <textarea
                  className="form-control" // Bootstrap class for styling textareas
                  id="content"
                  name="content"
                  value={newNotification.content}
                  onChange={handleInputChange}
                  style={{ minHeight: '300px' }} // Set a minimum height
                />
              </FormGroup>
            </Col>
            <Col md="7">
              <FormGroup>
                <label htmlFor="content" className="textarea-label">Arabic Content:</label>
                <textarea
                  className="form-control" // Bootstrap class for styling textareas
                  id="content_ar"
                  name="content_ar"
                  value={newNotification.content_ar}
                  onChange={handleInputChange}
                  style={{ minHeight: '300px' }} // Set a minimum height
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="update ml-auto mr-auto">
                <Button className="btn-round" color="primary" type="submit">
                  send Notification
                </Button>
                {addSuccessMessage && (
                  <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
                    {addSuccessMessage}
                  </div>
  )              }
  {addErrorMessage && (
    <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
      {addErrorMessage}
    </div>
  )}
              </div>
            </Col>
          </Row>
        </Form>
        <CardHeader>
          <CardTitle tag="h4">Notification List</CardTitle>
        </CardHeader>
        <CardFooter>
          <div>Notifications Total : {notificationCount}</div>
        </CardFooter>
        {loading ? (
          <p>Loading notifications...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <>
            <Table>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Arabic Title</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Content</th>
                  <th>Arabic Content</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
              {notifications.map((notification) => (
  <tr key={notification.id}>
                   <td>{notification.title}</td>
                   <td>{notification.title_ar}</td>
                   <td>{formatDateToReadableString(notification.date)}</td>
                   <td>{formatTime12Hour(notification.time)}</td>
                   <td>{notification.content}</td>
                   <td>{notification.content_ar}</td>
                   <td>
                     <Button 
                      color="success"
                     onClick={() => handleUpdate(notification)} >
                       Update
                     </Button>
                     </td>
                     {' '}
                     <td>
                     <Button 
                      color="danger"
                     onClick={() => handleDelete(notification.id)} >
                       Delete
                     </Button>
                     
                   </td>
                 </tr>
               ))}
              </tbody>
            </Table>
            {showUpdateForm && (
              <Form onSubmit={handleNotificationUpdate} style={{ marginTop: '100px' }}>
                <Row>
                  <Col md="7">
                    <FormGroup>
                      <label>Title:</label>
                      <Input
                        type="text"
                        name="title"
                        id="update-notification-title" 
                        value={updateNotification.title}
                        onChange={(e) =>
                          setUpdateNotification({
                            ...updateNotification,
                            title: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="7">
                    <FormGroup>
                      <label>Arabic Title:</label>
                      <Input
                        type="text"
                        name="title_ar"
                        id="update-notification-title_ar" 
                        value={updateNotification.title_ar}
                        onChange={(e) =>
                          setUpdateNotification({
                            ...updateNotification,
                            title_ar: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="7">
                    <FormGroup>
                      <label>Date:</label>
                      <Input
                        type="date"
                        name="date"
                        value={updateNotification.date}
                        onChange={(e) =>
                          setUpdateNotification({
                            ...updateNotification,
                            date: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="7">
                   <FormGroup>
                     <label>Time:</label>
                     <Input
                       type="time"
                       name="time"
                       value={updateNotification.time}
                       onChange={(e) =>
                         setUpdateNotification({
                           ...updateNotification,
                           time: e.target.value,
                         })
                       }
                     />
                   </FormGroup>
                 </Col>

                  <Col md="7">
                    <FormGroup>
                      <label for="content" className="textarea-label">Content:</label>
                      <textarea
                        type="text" 
                        id="content"
                        name="content"
                        value={updateNotification.content}
                        onChange={(e) =>
                          setUpdateNotification({
                            ...updateNotification,
                            content: e.target.value,
                          })
                        }
                        style={{ minHeight: '300px', width: '695px'  }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="7">
                    <FormGroup>
                      <label for="content" className="textarea-label">Arabic Content:</label>
                      <textarea
                        type="text" 
                        id="content_ar"
                        name="content_ar"
                        value={updateNotification.content_ar}
                        onChange={(e) =>
                          setUpdateNotification({
                            ...updateNotification,
                            content_ar: e.target.value,
                          })
                        }
                        style={{ minHeight: '300px', width: '695px'  }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                
      <Button type="submit" color="primary">
        Update Notification
      </Button>
      <Button color="secondary" onClick={() => setShowUpdateForm(false)}>
        Cancel
      </Button>{" "}
      {updateSuccessMessage && (
      <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
        {updateSuccessMessage}
      </div>
    )}

    {updateErrorMessage && (
      <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
        {updateErrorMessage}
      </div>
    )}
   
  </Form>
)}
          </>
        )}
        <Modal isOpen={showDeletePopup} toggle={() => setShowDeletePopup(false)}>
  <ModalHeader toggle={() => setShowDeletePopup(false)}>Confirm Delete</ModalHeader>
  <ModalBody>
    Are you sure you want to delete this notification?
  </ModalBody>
  <ModalFooter>
    <Button color="danger" onClick={performDelete}>Delete</Button>{' '}
    <Button color="secondary" onClick={() => setShowDeletePopup(false)}>Cancel</Button>
  </ModalFooter>
</Modal>

      </div>
    </>
  );
}

export default NotificationsComponent;
