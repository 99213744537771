import React, { useEffect, useState, Fragment } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  Input,
  Button,
} from "reactstrap";
import "../assets/css/style.css";

function UserManagement() {

  // State variables for users, loading, and other necessary data
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [newUser, setNewUser] = useState({
    fullName: "",
    email: "",
    Image: "",
    PhoneNumber: "",
    gender: "",
    birthdate: "",
    nationality: "",
    city: "",
  });
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showMembersOnly, setShowMembersOnly] = useState(false);

const toggleMembersVisibility = () => {
  setShowMembersOnly(!showMembersOnly);
};

const toggleMemberDisplay = (userId) => {
  setSelectedUserId(selectedUserId === userId ? null : userId); // Toggle the display of members
};


  const navigate = useNavigate();

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Function to fetch the list of users
  const fetchUsers = async () => {
    try {
      const response = await axios.get("https://digitalback.we-demo.xyz/user/list");
      setUsers(response.data.data);
      setLoading(false);
    } catch (error) {
      setError("Error fetching users. Please try again later.");
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchUsers();
  }, []);
  


  // Function to handle form submission for adding a new user
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("https://digitalback.we-demo.xyz/user/add", newUser);
      console.log("User added successfully:", response.data);

      // Clear the input fields
      setNewUser({
        fullName: "",
        email: "",
        image: "",
        PhoneNumber: "",
        gender: "",
        birthdate: "",
        nationality: "",
        city: "",
      });


    
    
    

      // Fetch the updated list of users
      fetchUsers();
    } catch (error) {
      console.error("Error adding user:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

    // Filter users based on the search term
const filteredUsers = users.filter(user => 
  user.PhoneNumber ? user.PhoneNumber.includes(searchTerm) : false
);


  return (
    <>
    <div className="content">
      <Card >
        <CardHeader>
          <CardTitle tag="h4">User List</CardTitle>
        </CardHeader>
        <CardFooter>
          <div>Total Users: {users.length}</div>
        </CardFooter>

        <Row className="justify-content-center" style={{ marginTop: "22px", marginBottom: "22px" }}>
          <Col md="4" className="text-center">
            <Input
              type="text"
              placeholder="Search by Phone Number"
              value={searchTerm}
              onChange={handleSearchChange}
              style={{
                backgroundColor: "#f8f9fa",
                color: "#333",
                border: "3px solid #ced4da",
                borderRadius: "0.25rem",
              }}
            />
          </Col>
        </Row>

        <CardBody >
  <Table responsive >
    <thead className="text-primary text-center " >
      <tr>
        <th>Full Name</th>
        <th>Email</th>
        <th>Image</th>
        <th>Phone Number</th>
        <th>Gender</th>
        <th>Birthdate</th>
        <th>Nationality</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      {filteredUsers.map((user) => (
        <Fragment key={user.id}>
          <tr>
            <td className="text-center" style={{ fontSize: '13px', '@media screen and (min-width: 576px)': { fontSize: '14px' }, '@media screen and (min-width: 768px)': { fontSize: '18px' }, '@media screen and (min-width: 992px)': { fontSize: '20px' }, '@media screen and (min-width: 1200px)': { fontSize: '22px' }, '@media screen and (min-width: 1400px)': { fontSize: '24px' } }}>{user.fullName}</td>
            <td className="text-center" style={{ fontSize: '13px', '@media screen and (min-width: 576px)': { fontSize: '14px' }, '@media screen and (min-width: 768px)': { fontSize: '18px' }, '@media screen and (min-width: 992px)': { fontSize: '20px' }, '@media screen and (min-width: 1200px)': { fontSize: '22px' }, '@media screen and (min-width: 1400px)': { fontSize: '24px' } }}>{user.email}</td>
            <td className="text-center" style={{ fontSize: '13px', '@media screen and (min-width: 576px)': { fontSize: '14px' }, '@media screen and (min-width: 768px)': { fontSize: '18px' }, '@media screen and (min-width: 992px)': { fontSize: '20px' }, '@media screen and (min-width: 1200px)': { fontSize: '22px' }, '@media screen and (min-width: 1400px)': { fontSize: '24px' } }}>
              <img
                src={`https://digitalback.we-demo.xyz/uploads/${user.image}`}
                alt={user.fullName}
                style={{ maxWidth: "100%" }}
              />
            </td>
            <td className="text-center" style={{ fontSize: '13px', '@media screen and (min-width: 576px)': { fontSize: '16px' }, '@media screen and (min-width: 768px)': { fontSize: '20px' }, '@media screen and (min-width: 992px)': { fontSize: '22px' }, '@media screen and (min-width: 1200px)': { fontSize: '24px' }, '@media screen and (min-width: 1400px)': { fontSize: '26px' } }}>{user.PhoneNumber}</td>
            <td className="text-center" style={{ fontSize: '13px', '@media screen and (min-width: 576px)': { fontSize: '16px' }, '@media screen and (min-width: 768px)': { fontSize: '20px' }, '@media screen and (min-width: 992px)': { fontSize: '22px' }, '@media screen and (min-width: 1200px)': { fontSize: '24px' }, '@media screen and (min-width: 1400px)': { fontSize: '26px' } }}>{user.gender}</td>
            <td className="text-center" style={{ fontSize: '13px', '@media screen and (min-width: 576px)': { fontSize: '16px' }, '@media screen and (min-width: 768px)': { fontSize: '20px' }, '@media screen and (min-width: 992px)': { fontSize: '22px' }, '@media screen and (min-width: 1200px)': { fontSize: '24px' }, '@media screen and (min-width: 1400px)': { fontSize: '26px' } }}>{user.birthdate}</td>
            <td className="text-center" style={{ fontSize: '13px', '@media screen and (min-width: 576px)': { fontSize: '16px' }, '@media screen and (min-width: 768px)': { fontSize: '20px' }, '@media screen and (min-width: 992px)': { fontSize: '22px' }, '@media screen and (min-width: 1200px)': { fontSize: '24px' }, '@media screen and (min-width: 1400px)': { fontSize: '26px' } }}>{user.nationality}</td>
            <td className="text-center" style={{ fontSize: '13px', '@media screen and (min-width: 576px)': { fontSize: '16px' }, '@media screen and (min-width: 768px)': { fontSize: '20px' }, '@media screen and (min-width: 992px)': { fontSize: '22px' }, '@media screen and (min-width: 1200px)': { fontSize: '24px' }, '@media screen and (min-width: 1400px)': { fontSize: '26px' } }}>
              <Button
                onClick={() => toggleMemberDisplay(user.id)}
                className="btn btn-success"
                style={{ height: '50px', fontSize: '12px' }}
              >
                {selectedUserId === user.id ? 'Hide Members' : 'Show Members'}
              </Button>
            </td>
          </tr>
          {selectedUserId === user.id && user.members && user.members.map((member) => (
            
            <tr key={member.id}>
              <td></td>
              <td className="text-center" style={{ fontSize: '14px', '@media screen and (min-width: 576px)': { fontSize: '16px' }, '@media screen and (min-width: 768px)': { fontSize: '20px' }, '@media screen and (min-width: 992px)': { fontSize: '22px' }, '@media screen and (min-width: 1200px)': { fontSize: '24px' }, '@media screen and (min-width: 1400px)': { fontSize: '26px' } }}>{member.fullname}</td>
              <td className="text-center" style={{ fontSize: '14px', '@media screen and (min-width: 576px)': { fontSize: '16px' }, '@media screen and (min-width: 768px)': { fontSize: '20px' }, '@media screen and (min-width: 992px)': { fontSize: '22px' }, '@media screen and (min-width: 1200px)': { fontSize: '24px' }, '@media screen and (min-width: 1400px)': { fontSize: '26px' } }}>{member.gender}</td>
              <td className="text-center" style={{ fontSize: '14px', '@media screen and (min-width: 576px)': { fontSize: '16px' }, '@media screen and (min-width: 768px)': { fontSize: '20px' }, '@media screen and (min-width: 992px)': { fontSize: '22px' }, '@media screen and (min-width: 1200px)': { fontSize: '24px' }, '@media screen and (min-width: 1400px)': { fontSize: '26px' } }}>{member.birthdate}</td>
              <td className="text-center" style={{ fontSize: '14px', '@media screen and (min-width: 576px)': { fontSize: '16px' }, '@media screen and (min-width: 768px)': { fontSize: '20px' }, '@media screen and (min-width: 992px)': { fontSize: '22px' }, '@media screen and (min-width: 1200px)': { fontSize: '24px' }, '@media screen and (min-width: 1400px)': { fontSize: '26px' } }}>{member.nationality}</td>
              <td>
                <img
                  src={`https://digitalback.we-demo.xyz/uploads/${member.image}`}
                  alt={member.fullname}
                  style={{ maxWidth: "100%" }}
                />
              </td>
            </tr>
          ))}
        </Fragment>
      ))}
    </tbody>
  </Table>
</CardBody>

      </Card>
    </div>
  </>
  );
}

export default UserManagement;
