import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, CardBody, CardFooter, CardTitle, Row, Col } from "reactstrap";
import { useProtectedRoute } from './useProtectedRoute';

function Dashboard() {
  // const [gallerycount, setgallery] = useState([]);
  // const [SocialMediacount, setSocialMedia] = useState([]);
  const [SocialMediacount, setSocialMedia] = useState([]);
  const [HomePage, setHomePage] = useState([]);
  const [about, setabout] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [TankShop, setTankShop] = useState([]);
  const [PrivacyPolicies, setPrivacyPolicies] = useState([]);
  const [termCount, setTermCount] = useState(0);
  const [isUpdateClicked, setIsUpdateClicked] = useState(false);
  const [eventCount, setEventCount] = useState([]);
  const [qrcodeCount, setqrcodeCount] = useState([]);
  const [notification, setnotification] = useState([]);
  const [About2, setAbout2] = useState([]);
  
  const userRole = 'admin'; // Fetch the user's role or use the state you've stored

  // Protect this route for 'admin' and 'superadmin' roles
  useProtectedRoute(['admin', 'superadmin'], userRole);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://digitalback.we-demo.xyz/event/list"
        );
        // setProduct(response.data);
        console.log(`rrr${response}`)
        setEventCount(response.data.data);
      } catch (error) {
        console.log(`Error getting news from frontend: ${error}`);
      }
    };

    fetchData();
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(
  //         "https://digitalback.we-demo.xyz/gallery/images"
  //       );
  //       setgallery(response.data.data);
  //     } catch (error) {
  //       console.log(`Error getting Blog from frontend: ${error}`);
  //     }
  //   };

  //   fetchData();
  // }, []);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(
  //         "https://digitalback.we-demo.xyz/social_media/list"
  //       );
  //       setSocialMedia(response.data.data);
  //     } catch (error) {
  //       console.log(`Error getting Blog from frontend: ${error}`);
  //     }
  //   };

  //   fetchData();
  // }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://digitalback.we-demo.xyz/icons/list"
          
        );
        setSocialMedia(response.data.data);
      } catch (error) {
        console.log(`Error getting Blog from frontend: ${error}`);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://digitalback.we-demo.xyz/background/list"
        );
        setHomePage(response.data.data);
      } catch (error) {
        console.log(`Error getting Blog from frontend: ${error}`);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://digitalback.we-demo.xyz/about/list2"
          // "https://seashell-app-6v6yj.ondigitalocean.app/about/list"
        );
        setabout(response.data.data);
        console.log(`aaa${response}`)
      } catch (error) {
        console.log(`Error getting Blog from frontend: ${error}`);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://digitalback.we-demo.xyz/user/count"
        );
        const userCount = response.data.data[0].count; // Access the count property inside data
        setUserCount(userCount);
      } catch (error) {
        console.log(`Error getting Blog from frontend: ${error}`);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://digitalback.we-demo.xyz/shop/list"
        );
        setTankShop(response.data.data);
      } catch (error) {
        console.log(`Error getting Blog from frontend: ${error}`);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://digitalback.we-demo.xyz/privacy/list"
        );
        setPrivacyPolicies(response.data.data);
      } catch (error) {
        console.log(`Error getting Blog from frontend: ${error}`);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://digitalback.we-demo.xyz/terms/list");
        if (response.data && Array.isArray(response.data.data)) {
          setTermCount(response.data.data.length); // Correctly set the count of terms
        } else {
          console.error("Unexpected response format:", response.data);
          // Handle this situation appropriately
        }
      } catch (error) {
        console.error(`Error getting terms from frontend: ${error}`);
      }
    };
  
    fetchData();
  }, []);
  
  
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://digitalback.we-demo.xyz/qr/list"
        );
        setqrcodeCount(response.data.data);
      } catch (error) {
        console.log(`Error getting Blog from frontend: ${error}`);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://digitalback.we-demo.xyz/notification/list"
        );
        setnotification(response.data.data);
      } catch (error) {
        console.log(`Error getting Blog from frontend: ${error}`);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://digitalback.we-demo.xyz/walk/list2"
        );
        setAbout2(response.data.data);
      } catch (error) {
        console.log(`Error getting Blog from frontend: ${error}`);
      }
    };

    fetchData();
  }, []);

  const handleUpdateClick = () => {
    setIsUpdateClicked(true);
  };
  return (
    <>
      <div className="content">
        <Row>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-calendar-60 text-warning" />
                    </div>
              
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Events</p>
                      <CardTitle tag="p">{eventCount.length}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
              </CardFooter>
            </Card>
          </Col>
          {/* <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-album-2 text-success" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Gallery</p>
                      <CardTitle tag="p">{gallerycount.length}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
              </CardFooter>
            </Card>
          </Col> */}
          {/* <Col lg="3" md="6" sm="6"> */}
            {/* <Card className="card-stats"> */}
              {/* <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chat-33 text-success" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Social Media</p>
                      <CardTitle tag="p">{SocialMediacount.length}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody> */}
              {/* <CardFooter>
                <hr />
              </CardFooter>
            </Card>
          </Col> */}
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chat-33 text-success" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Social Media</p>
                      <CardTitle tag="p">{SocialMediacount.length}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-image text-success" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Home Page</p>
                      <CardTitle tag="p">{HomePage.length}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-badge text-success" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">About</p>
                      <CardTitle tag="p">{about.length}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-single-02 text-danger" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">users</p>
                      <CardTitle tag="p">{userCount}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-delivery-fast text-primary" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Tank Shop</p>
                      <CardTitle tag="p">{TankShop.length}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-lock-circle-open text-warning" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Privacy Policies</p>
                      <CardTitle tag="p">{1}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-paper text-warning" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
  <div className="numbers">
    <p className="card-category">Terms</p>
    <CardTitle tag="p">{1}</CardTitle>
 {/* Display term count here */}
    <p />
  </div>
</Col>

                </Row>
              </CardBody>
              <CardFooter>
                <hr />
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-mobile text-warning" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">QR Code</p>
                      <CardTitle tag="p">{qrcodeCount.length}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-bell-55 text-warning" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Notifications</p>
                      <CardTitle tag="p">{notification.length}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
              </CardFooter>
            </Card>
          </Col>

          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-bell-55 text-warning" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Walk Through</p>
                      <CardTitle tag="p">{About2.length}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
              </CardFooter>
            </Card>
          </Col>
        </Row>
    
      </div>
    </>
  );
}

export default Dashboard;
